import Highcharts, { ColorString, GradientColorObject, PatternObject, TooltipFormatterCallbackFunction } from 'highcharts'

export type rangesType = Array<rangeType>
type rangeType = [number, number, number]
export type averagesType = Array<averageType>
type averageType = [number, number]

const chartOptions = Highcharts?.getOptions()
export const chartColors = chartOptions.colors || []

export type optionsType = {
  title: { text: string },
  xAxis: {
    type?: string,
    title: {
      text: string | undefined
    },
    labels?: {
      format: string
    },
    categories?: string[]
  }
  yAxis: {
    type?: string,
    title: {
      text: string | undefined
    },
    labels?: {
      format: string
    },
    categories?: string[]
  },
  tooltip: {
    crosshairs: boolean,
    shared: boolean,
    valueSuffix?: string,
    formatter?: TooltipFormatterCallbackFunction;
  },
  series: serieType[]
}

type serieType = {
  name: string
  type?: string
  id?: string
  lineWidth?: number
  color: (ColorString|GradientColorObject|PatternObject)
  fillOpacity?: number
  linkedTo?: string
  data: averagesType | rangesType
  zIndex: number
  marker: markerType
}

type markerType = {
  fillColor?: string
  lineWidth?: number
  lineColor?: (ColorString|GradientColorObject|PatternObject)
  enabled?: boolean
}


export const defaultOptions: optionsType = {
  title: {
    text: ''
  },

  xAxis: {
    type: 'datetime',
    title: {
      text: undefined
    }
  },

  yAxis: {
    title: {
      text: undefined
    }
  },

  tooltip: {
    crosshairs: true,
    shared: true,
    valueSuffix: '',
    formatter: undefined
  },

  series: []
}
