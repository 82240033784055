import { useEffect, useState } from "react"
import { fetchApi } from "../auth"
import { components } from "../generated/flowcoordination"
import { useErrorAndLoadingDisplay } from "../utils"

import './styles.scss'

type TrackerOverviewProps = {
    title: string;
    description?: string;
    onPageClickEvent?: (targetPage: string, customData: any) => void;
}

export const TrackerOverview = ({ title, description, onPageClickEvent }: TrackerOverviewProps) => {
    const [trackers, setTrackers] = useState<components["schemas"]["VitruCare_Web_ConversationFlow.Clients.TrackerInformation"][]>()
    const [error, setError] = useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const showErrorOrLoading = useErrorAndLoadingDisplay(isLoading, error)

    useEffect(() => {
        setError(undefined)

        const getTrackers = async () => {
            setIsLoading(true)
            try {
                const res = await fetchApi('/api/trackers')
                const json = await res.json()
                if (!res.ok) {
                    setError(`Error ${json.status} - ${json.title}`)
                } else {
                    setTrackers(json)
                }
                setIsLoading(false)
            } catch (error) {
                // @ts-ignore
                setError(error)
            }
        }

        if (!trackers) {
            getTrackers()
            setIsLoading(false)
        }
    }, [trackers]);

    return (
        <>
            {showErrorOrLoading}
            {trackers &&
                <>
                    <h2>{title}</h2>
                    {trackers.length === 0 && <p>You don't have any trackers</p>}

                    {trackers.length > 0 &&
                        <>
                            <p>{description}</p>

                            <ul className='tracker-list'>
                                {trackers.map((currentTracker, index) => {
                                    return (
                                        <li key={index}><span onClick={() => onPageClickEvent ? onPageClickEvent("TrackerDetails", { tracker: currentTracker }) : undefined}>{currentTracker.externalSystemCodeMapping.display}</span></li>
                                    )
                                })}
                            </ul>
                        </>}
                </>
            }
        </>
    )
}

