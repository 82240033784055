import { Icon } from '@fluentui/react/lib/Icon'
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona'
import botIcon from '../../../images/bot-icon.png'
import './style.scss'
// @ts-ignore
var classNames = require('classnames')

type CategoryButtonProps = {
  isHeadCategory: boolean
  title: string
  iconUrl: string
  id: string
  isActive: boolean
  dialog: dialogProps
  changeDialogView: (dialogName: string, categoryId: string, newFlowId?: string) => void
}

type dialogProps = {
  dialogName: string
  infoCards: Array<any>
  introduction: string
  title: string
  flowId?: string;
}

const CategoryButton = ({ isHeadCategory, title, iconUrl, id, isActive, dialog, changeDialogView }: CategoryButtonProps) => {
  const iconType = isHeadCategory ? 'head' : 'category'
  const iconClasses = classNames('icon', iconType)
  const buttonClasses = classNames('category-button', isActive && 'active', isHeadCategory && 'main')
  const iconUrlWithDefault = iconUrl ? iconUrl : isHeadCategory ? botIcon : ''

  const iconTypeLookup = {
    'head': 'SkypeMessage',
    'category': 'InfoSolid'
  }

  return (
    <button
      className={buttonClasses}
      onClick={() => changeDialogView(dialog.dialogName, id, dialog.flowId)}>
      {iconUrlWithDefault ?
        <Persona
          size={PersonaSize.size32}
          hidePersonaDetails
          imageUrl={iconUrlWithDefault}
          imageAlt={title}
        />
        : <Icon className={iconClasses} iconName={iconTypeLookup[iconType]} />
      }
      <span>{title}</span>
    </button>
  )
}

export default CategoryButton
