import React, { useState, useEffect } from 'react'
import WarningDisplay from '../components/CBT/WarningDisplay'

const useWarningDisplay = (warningText: string | undefined, type: 'info' | 'error') => {
  const [warning, setWarning] = useState<string | undefined>(undefined)

  useEffect(() => {
    setWarning(warningText)
  }, [warningText, setWarning])

  if (warning) {
    return <WarningDisplay text={warning} type={type} />
  }
}

export default useWarningDisplay