export class LocalSession {

    /**
     * Checks if the passed string is valid json.
     * @param rawString Raw string
     * @returns true if string is valid json, else false
     */
    private static isJson = (rawString: string): boolean => {
        try {
            JSON.parse(rawString);
            return true;
        } catch (e) {
            return false;
        }
    }

    /**
     * Sets the item in the local session. If the passed item is an object, it is serialized first (as json).
     * @param key String containing the key of the item.
     * @param item Instance of type T.
     */
    public static setItem = <T>(key: string, item: T) => {
        let stringValue: string;

        if (typeof (item) === "object") {
            stringValue = JSON.stringify(item);
        }
        else {
            stringValue = (item as any).toString();
        }

        sessionStorage.setItem(key, stringValue);
    }

    /**
     * Gets the item if present in the local session.
     * @param key String containing the key of the item.
     * @returns If the item is present, and it is serialized json, the object is deserialized first. 
     * If the item is not serialized json, the raw value is returned (as T).
     */
    public static getItem = <T>(key: string): T | undefined => {
        const rawValue = sessionStorage.getItem(key);

        if (rawValue) {
            if (this.isJson(rawValue)) {
                return JSON.parse(rawValue) as T;
            }
            else {
                return (rawValue as any) as T;
            }
        }

        return undefined;
    }

    /**
     * Checks if the local session contains an item with the specified key.
     * @param key String containing the key of the item.
     * @returns True if the local session contains an item with the specified key, else false.
     */
    public static hasItem = (key: string): boolean => {
        let keys = Object.keys(sessionStorage);
        return keys.indexOf(key) >= 0;
    }

    /**
     * Removes the item from the local session.
     * @param key String containing the key of the item.
     */
    public static removeItem = (key: string) => {
        sessionStorage.removeItem(key);
    }
}