import { CallToActionButton as BaseCallToActionButton } from 'dhs-default-base/dist'

type CallToActionButtonProps = {
  url?: string
  name: string
  target?: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined
  clickFunction?: () => void
}

const CallToActionButton = (props: CallToActionButtonProps) => {
  return <BaseCallToActionButton {...props} />
}

export default CallToActionButton
