import { PageTitle as BasePageTitle } from 'dhs-default-base/dist'

type PageTitleProps = {
  text: string
}

const PageTitle = (props: PageTitleProps) => {
  return <BasePageTitle {...props} />
}

export default PageTitle
