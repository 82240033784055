import { useState, useEffect, useRef } from 'react'
import DynamicViews from '../../components/CBT/DynamicViews'
import CBTPage from './CBTPage'
import { components } from '../../generated/flowcoordination'
import { getStorage, clearStorage, checkFilledDayparts, setGlobalId, addEntity, getGlobalId, removeLastEntity } from '../../components/CBT/Util/pageStorage'
import { optionsProps, storageProps } from '../../components/CBT/Util/pageStorage/storageTypes'
import { useWarningDisplay, useErrorAndLoadingDisplay } from '../../utils'
import CallToActionButton from '../../components/CBT/CallToActionButton'
import { fetchApi } from '../../auth'

type CBTProps = {
  flowId: string;
  actAsId?: string;
  stopPage?: () => void;
}

export default function CBT(props: CBTProps) {
  const [currentPage, setCurrentPage] = useState<string>()
  const [flowTitle, setFlowTitle] = useState<string>()
  const [stopUrl, setStopUrl] = useState<string | undefined>()
  const [data, setData] = useState<components["schemas"]["VitruCare_Web_ConversationFlow.Models.Page"]>()
  const [translations, setTranslations] = useState<components["schemas"]["VitruCare_Web_ConversationFlow.Models.PageTranslations"] | undefined>(data?.translations)
  const [isNewSession, setIsNewSession] = useState<boolean>(false)
  const [dynamicComponents, setDynamicComponents] = useState<Array<components["schemas"]["VitruCare_Web_ConversationFlow.Models.Component"]>>([])

  const [error, setError] = useState<string | undefined>(undefined)
  const [warning, setWarning] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const showErrorOrLoading = useErrorAndLoadingDisplay(isLoading, error)
  const showWarning = useWarningDisplay(warning, 'error')

  const cbtMainRef = useRef<HTMLElement>(null)

  const { flowId, actAsId } = props;

  const postSendData = async (sendData: storageProps) => {
    const apiUrl = sendData.constants?.apiUrl
    if (apiUrl) {
      delete sendData.constants?.apiUrl
      delete sendData.constants?.activitiesRequired
      delete sendData.constants?.daypartsRequired
      const extraOptions = {
        flowId: flowId,
        pageId: currentPage,
        created: new Date()
      }
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
        body: JSON.stringify({ ...sendData.options, ...sendData.constants, ...extraOptions })
      }
      try {
        const res = await fetchApi(apiUrl, requestOptions)
        if (res.status !== 200) {
          setError(`${res.statusText} - ${res.status}`)
        }
      } catch (error) {
        // @ts-ignore
        setError(error)
      }
    }
  }

  const changePage = async (pageData?: components['schemas']['VitruCare_Web_ConversationFlow.Models.PageReference'] | undefined, buttonType?: 'stop' | 'continue' | 'next' | 'prev') => {

    // re-add scroll from main
    cbtMainRef.current?.classList.remove('no-scroll')

    const sendData: Array<storageProps> | undefined = getStorage()

    setWarning(undefined)
    setIsLoading(true)
    if (buttonType === 'continue' && data?.continuePage) {
      if (data.continuePage.entityContext)
        data.continuePage.entityContext.forEach((id) => addEntity(id))
      setCurrentPage(data.continuePage.id)
    } else if (!props.actAsId && sendData && sendData.length > 0) {
      var hasError: boolean = false

      for (let i = 0; i < sendData.length; i++) {
        const { options, constants } = sendData[i]
        if (options && constants && pageData && pageData.id) {

          // We need to synchronize the changes options the user has entered with the data member which is bound to the component
          // This to ensure the re-render uses the most recent model with the actual values the user entered in the component          
          // NOTE: this synchronization happens only for ListCardInput components for now
          if (constants.componentId) {
            synchronizeListCardInputValuesWithData(constants.componentId, options);
          }

          if (
            (constants.activitiesRequired && buttonType === 'next') &&
            (options.activities && options.activities.length === 0)) {
            setWarning("Please add at least one activity before continuing.")
            hasError = true
          } else if ((constants.cardsRequired && buttonType === 'next') &&
            (!options.itemValues || (options.itemValues && options.itemValues.length === 0) || options.hasAllFields === false)) {
            setWarning(`${translations?.validationRequired} ${translations?.validationAtLeastOneRequired}` || "Please add at least one card and fill all fields.")
            hasError = true
          } else if (buttonType === 'next' && constants.daypartsRequired && checkFilledDayparts(options.activities)) {
            setWarning("Please select a daypart for all available dropdowns")
            hasError = true
          } else {
            if (!(buttonType === 'prev' && constants.daypartsRequired))
              await postSendData(sendData[i])
          }
        }
      }

      if (!hasError && pageData) {
        clearStorage()
        pageData?.removeEntityContextItem && removeLastEntity()

        // In this case an explicit stop page (or stop url) is configured, so we need to use that

        if (buttonType === 'stop' && stopUrl) {
          window.location.href = stopUrl
        } else {
          pageData.id === currentPage ? refreshPage(pageData.id as string) : setCurrentPage(pageData.id)
        }
      }
      else if (buttonType === 'stop' && !pageData && !props.actAsId) {
        // In this case no explicit stop page is configfured for the stop button, so change the view to the first channel of the left nav
        if (props.stopPage) {
          props.stopPage()
        }
      }
    } else if (pageData && pageData.id) {
      if (pageData.removeEntityContextItem) {
        removeLastEntity()
        pageData.id === currentPage && refreshPage(pageData.id)
      } else {
        setCurrentPage(pageData.id)
      }

      setCurrentPage(pageData.id);
    }
    else if (buttonType === 'stop' && !pageData && !props.actAsId) {
      clearStorage()

      // In this case no explicit stop page is configfured for the stop button, so change the view to the first channel of the left nav
      if (props.stopPage) {
        props.stopPage()
      }
    }

    setIsLoading(false)
  }

  const synchronizeListCardInputValuesWithData = (componentId: string, options: optionsProps) => {
    if (componentId && data) {

      let clonedData: components["schemas"]["VitruCare_Web_ConversationFlow.Models.Page"] = { ...data };

      const componentIndex: number = clonedData.components
        .map((x: components["schemas"]["VitruCare_Web_ConversationFlow.Models.Component"]) => {
          return (x as components["schemas"]["VitruCare_Web_ConversationFlow.Models.Components.ListCardInput"]).componentId;
        })
        .indexOf(componentId);

      if (componentIndex > -1) {
        let listCardInputComponent: components["schemas"]["VitruCare_Web_ConversationFlow.Models.Components.ListCardInput"] = clonedData.components[componentIndex] as components["schemas"]["VitruCare_Web_ConversationFlow.Models.Components.ListCardInput"];

        if (listCardInputComponent) {
          listCardInputComponent.itemValues = options.itemValues;

          setData(clonedData);
        }
      }
    }
  }

  useEffect(() => {
    if (flowId) {
      setGlobalId("flow", flowId)
    }

    if (actAsId) {
      setGlobalId("act", actAsId)
    }
  }, [flowId, actAsId])

  useEffect(() => {
    if (data) {
      const newComponents: Array<components["schemas"]["VitruCare_Web_ConversationFlow.Models.Component"]> = []
      data.components.forEach((component: components["schemas"]["VitruCare_Web_ConversationFlow.Models.Component"]) => {
        newComponents.push(component)
      })
      setTranslations(data.translations)
      setGlobalId("page", data.id)
      setDynamicComponents(newComponents)
    }
  }, [data])

  const refreshPage = (pageId: string) => {
    setIsLoading(true)
    const apiUrl = `/api/flows/${flowId}/pages/${pageId}` + (actAsId ? `?act=${actAsId}` : '')
    const runFetch = async () => {
      try {
        const res = await fetchApi(apiUrl, {})
        const json = await res.json()
        if (!res.ok) {
          setError(`Error ${json.status} - ${json.title}`)
        } else {
          setData(json)
        }
        setIsLoading(false)
      } catch (error) {
        // @ts-ignore
        setError(error)
        setIsLoading(false)
      }
    }
    runFetch().then(() => setWarning(undefined))
  }

  const navigateToSubPage = (pageId?: string, entityId?: string) => {
    if (pageId) {
      setIsLoading(true)
      const apiUrl = `/api/flows/${flowId}/pages/${pageId}` + (entityId ? `/${entityId}` : '')
      if (entityId) addEntity(entityId)
      const runFetch = async () => {
        try {
          const res = await fetchApi(apiUrl, {})
          const json = await res.json()
          if (!res.ok) {
            setError(`Error ${json.status} - ${json.title}`)
          } else {
            setData(json)
          }
          setIsLoading(false)
        } catch (error) {
          // @ts-ignore
          setError(error)
          setIsLoading(false)
        }
      }
      // remove entity when navigated.
      if (!entityId) removeLastEntity()
      runFetch().then(() => setWarning(undefined))
    }
  }

  useEffect(() => {
    if (flowId) {
      const entities = getGlobalId('entities')
      const lastEntity = entities ? entities[entities.length - 1] : undefined

      var getType: 'flow' | 'page' = isNewSession ? 'flow' : (currentPage ? 'page' : 'flow')
      const apiUrl = `/api/flows/${flowId}` +
        (getType === 'page' ? `/pages/${currentPage}` + (lastEntity ? `/${lastEntity}` : '') : "") +
        (actAsId ? `?act=${actAsId}` : '')
      const options = {}
      const runFetch = async () => {
        setIsLoading(true)
        try {
          const res = await fetchApi(apiUrl, options)
          const json = await res.json()
          if (!res.ok) {
            setError(`Error ${json.status} - ${json.title}`)
          } else {
            const newData = getType === 'page' ? json : json.firstPage
            setData(newData)
            setIsNewSession(newData.isNewSession)
            if (getType === 'flow') {
              setStopUrl(json.stopUrl)
              setFlowTitle(json.title)
            }
          }
          clearStorage()
          setIsLoading(false)
        } catch (error) {
          // @ts-ignore
          setError(error)
        }
      }
      runFetch().then(() => {
        currentPage && setGlobalId("page", currentPage)
        setWarning(undefined)
        setIsLoading(false)
      })
    }
  }, [isNewSession, flowId, actAsId, currentPage])

  return (
    <>
      {showErrorOrLoading}
      {data && !isLoading &&
        <CBTPage
          ref={cbtMainRef}
          pageTitle={flowTitle || ''}
          headerButtonText={translations?.navigationStop || 'Stop'}
          prevButtonText={translations?.navigationPrevious || 'Previous'}
          nextButtonText={translations?.navigationNext || 'Next'}
          prevButton={data.prevPage}
          nextButton={data.nextPage}
          stopPageId={data.stopPage}
          stopUrl={stopUrl}
          enableStopButton={!props.actAsId}
          pageNumberPrefixText={translations?.navigationPageNumberPrefix || ''}
          pageNumber={data.pageNumber}
          pageTotal={data.pageTotal}
          changePage={changePage}
        >
          <>
            <DynamicViews
              messageData={dynamicComponents}
              refreshPage={refreshPage}
              navigateToSubPage={navigateToSubPage} />
            {data.continuePage &&
              <CallToActionButton
                name={translations?.navigationContinue || 'Continue where I left off'}
                clickFunction={() => changePage(data.continuePage, 'continue')}
              />
            }
            {showWarning}
          </>
        </CBTPage>
      }
    </>
  )
}
