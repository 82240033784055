import React, { lazy, useState, useEffect } from 'react'
import { Spinner } from '@fluentui/react/lib/Spinner'
import { components } from '../../../generated/flowcoordination'

export interface DynamicViewsProps {
  messageData: Array<components["schemas"]["VitruCare_Web_ConversationFlow.Models.Component"]>
  refreshPage?: (pageId: string) => void
  navigateToSubPage?: (pageId?: string, entityId?: string) => void
}

const importView = (componentName: string) =>
  lazy(() =>
    import(`../${componentName}`).catch((ex : Error) => {
      console.log(`Unable to load ${componentName}: ${ex.name} - ${ex.message}`)
      import(`../ComponentNotFound`)
    })
  )

const DynamicViews = ({messageData, refreshPage, navigateToSubPage}: DynamicViewsProps) => {
  const [views, setViews] = useState([])

  useEffect(() => {
    let isMounted = true
    let controller = new AbortController()
    async function loadViews() {
      const componentPromises = messageData.map(async (data: components["schemas"]["VitruCare_Web_ConversationFlow.Models.Component"], index: number) => {
        const View = await importView(data.componentName)
        return <View key={index} {...data} refreshPage={refreshPage} navigateToSubPage={navigateToSubPage}/>
      })

      // @ts-ignore      
      Promise.all(componentPromises).then((result: never[]) => {
        if (isMounted)
          setViews(result)
      }).catch(error => {
        if (error.name === "AbortError") return
        throw error
      })
    }

    loadViews();

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [messageData, refreshPage, navigateToSubPage])

  return (
    <React.Suspense fallback={<Spinner />}>
      {views}
    </React.Suspense>
  )
}

export default DynamicViews
