import { TitleMessage as BaseTitleMessage } from 'dhs-default-base/dist'
import { components } from '../../../generated/flowcoordination'

const TitleMessage = (props: components["schemas"]["VitruCare_Web_ConversationFlow.Models.Components.TitleMessage"]) => {
  return (
    <BaseTitleMessage {...props}/>
  )
}

export default TitleMessage
