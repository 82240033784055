import { Icon } from '@fluentui/react/lib/Icon'
import CookieConsent from "react-cookie-consent";
import './style.scss'

const CookieBanner = () => {

    const cookieBannerDismissed = () => {
        window.setTimeout(() => {
            window.recalculateHeight();
        }, 50);
    }

    return (
        <CookieConsent
            location="top"
            style={{ background: "#FAF9F8", height: 73, position: "relative" }}
            enableDeclineButton={true}
            disableButtonStyles={true}
            flipButtons
            declineButtonClasses="btn btn-secondary"
            declineButtonText="Deny"
            buttonClasses="btn btn-primary"
            buttonText="Accept"
            buttonWrapperClasses="btn-container"
            onAccept={cookieBannerDismissed}
            onDecline={cookieBannerDismissed}
        >
            <div className='text-container'>
                <Icon className='icon' iconName='Info' />
                <span className='cookie-text'>This website uses cookies to enhance the user experience. Read our <a href="https://vc.vitrucare.com/Landing/PrivacyPolicy/Lincolnshire" target="_blank">Privacy policy</a></span>
            </div>
        </CookieConsent>
    )
}

export default CookieBanner
