import '../styles/landingpage/_index.scss'
import { ReactElement, useEffect, useState } from 'react'
import { useErrorAndLoadingDisplay } from '../utils'
import { Icon } from '@fluentui/react/lib/Icon'

type LandingPageProps = {
  children: ReactElement<any, any>
  goToAppScape: () => void
}

type link = {
  url: string
  name: string
}

const LandingPage = ({children, goToAppScape}: LandingPageProps) => {
  const [appScapeId, setAppScapeId] = useState<string>() // '06d0b5d6-5825-4bb8-881b-28309c9b6bd2'
  const [data, setData] = useState<any>()
  const [error, setError] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const showErrorOrLoading = useErrorAndLoadingDisplay(isLoading, error)

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const newAppScapeId: string | null = urlSearchParams.get('appscape')
    if (!appScapeId && newAppScapeId) {
      setAppScapeId(newAppScapeId)
    }
  }, [appScapeId])

  useEffect(() => {
    if (appScapeId) {
      const apiUrl = `/api/appscape/${appScapeId}/landingpage`
      const options = {}
      setError(undefined)
      const runFetch = async () => {
        setIsLoading(true)
        try {
          const res = await fetch(apiUrl, options)
          const json = await res.json()
          if (!res.ok) {
              setError(`Error ${json.status} - ${json.title}`)
          } else {
            setData(json)
          }
          setIsLoading(false)
        } catch (error) {
          // @ts-ignore
          setError(error)
        }
      }
      runFetch()
      setIsLoading(false)
    }
  }, [appScapeId])


  return (
    <>
      {showErrorOrLoading}
      {data && 
        <div className='landingpage'>
          <section className='landingpage-wrapper'>
            <main className='landingpage-main'>
              <div className='langindpage-content'>
                <h1>{data.title}</h1>
                <p>{data.introduction}</p>
              </div>
              <button onClick={() => goToAppScape()}>
                <span>{data.buttonText}</span>
                <Icon className='icon' iconName='IncreaseIndentArrow' />
              </button>
            </main>
          </section>
          {data.links.length > 0 && <footer className='landingpage-footer'>
            {
              data.links.map((link: link, index: number) => (
                <a key={index} href={link.url}>{link.name}</a>
              ))
            }
          </footer>}
        </div>}
      </>
    )
}

export default LandingPage