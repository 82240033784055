import { CommandBar } from '@fluentui/react'
import { PersonaSize, PersonaPresence } from '@fluentui/react/lib/Persona'
import { PersonaCoin } from 'office-ui-fabric-react'
import { useEffect, useState } from 'react'
import { UserInfo } from '../../../auth'
import './style.scss'

type TopNavigationProps = {
    userInfo?: UserInfo
    authenticationChange: () => void;
}

const TopNavigation = ({ userInfo, authenticationChange }: TopNavigationProps) => {
    const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
    const [userInitials, setUserInitials] = useState<string | undefined>()


    useEffect(() => {
        if (userInfo) {
            setLoggedIn(true)
            setUserInitials(`${userInfo.firstName?.charAt(0)}${userInfo.lastName?.charAt(0)}`.toUpperCase());
        }
    }, [userInfo])

    const getItems = () => {
        if (isLoggedIn) {
            // When user is authenticated, show a PersonaCoin with signout in dropdown
            return [
                {
                    onRenderMenuIcon: () => null,
                    onRenderChildren: () => {
                        return <PersonaCoin
                            text={userInfo?.name || ""}
                            size={PersonaSize.size40}
                            presence={PersonaPresence.none}
                            imageInitials={userInitials || ""}
                        />
                    },
                    key: 'authenticatedUser',
                    name: userInfo?.name || "",
                    iconOnly: true,
                    subMenuProps: {
                        items: [
                            {
                                key: 'signOut',
                                name: 'Sign out',
                                iconProps: { iconName: 'SignOut' },
                                onClick: authenticationChange
                            }
                        ],
                    },
                },
            ]
        }
        else {
            // When in anonymous mode, show login button
            return [
                {
                    key: 'signIn',
                    name: 'Sign in',
                    iconProps: { iconName: 'Contact' },
                    onClick: authenticationChange
                }
            ]
        }
    }

    return (
        <CommandBar
            items={[]}
            farItems={getItems()}
        />
    );
}

export default TopNavigation
