import { useRef, useState, useEffect } from 'react'
import Background from '../../images/background.jpg'
import { StyledLandingpageBackground } from './style'

type LandingBackgroundProps = {
  state: 'active' | 'stop' | 'finished' | 'desk' | 'fade'
}

const LandingBackground = ({ state }: LandingBackgroundProps) => {
  const [backgroundState, setBackgroundState] = useState<'active' | 'desk' | 'fade'>('active')
  const [currentBGposition, setCurrentBGposition] = useState<string>('')
  const [deskBGposition, setDeskBGposition] = useState<string>('')
  const [BGFinished, setBGFinished] = useState<boolean>(false)
  const landingpageRef = useRef<HTMLDivElement>(null)

  const updateCurrentBGPosition = () => {
    if (landingpageRef.current) {
      const landingbackgroundStyles = window.getComputedStyle(landingpageRef.current, null)
      landingpageRef.current.style.backgroundPositionX = landingbackgroundStyles.backgroundPositionX
      setCurrentBGposition(landingbackgroundStyles.backgroundPositionX)
    }
  }

  useEffect(() => {
    switch (state) {
      case 'active':
        setBackgroundState(state)
        break
      case 'desk':
          updateCurrentBGPosition()
          setBackgroundState(state)
          break
      case 'fade':
        updateCurrentBGPosition()
        setBackgroundState(state)
        break
      case 'finished':
        setBGFinished(true)
        break
    }
  }, [state])

  useEffect(() => {
    if (landingpageRef.current) {
      const imageWidth = (landingpageRef.current.clientHeight * 11350) / 2000
      const deskPositionCalc = (imageWidth / 2) - (landingpageRef.current.clientWidth / 1.5)
      setDeskBGposition(`-${deskPositionCalc}px`)
    }
  }, [])

  return <StyledLandingpageBackground 
    ref={landingpageRef}
    currentPosition={currentBGposition} 
    deskPosition={deskBGposition}
    backgroundImage={`url(${Background})`}
    state={backgroundState}
    finished={BGFinished}
  />
}

export default LandingBackground