import { DropdownInput as BaseDropdownInput } from 'dhs-default-base/dist'
import './style.scss'
import { isReadOnly } from '../Util/pageStorage'

type DropdownInputProps = {
  options: Array<option>
  placeholder?: string
  id: string
  selected?: Array<string>
  isDisabled: boolean
  onChangeFunction: (id: string, option: option, isMultiSelect?: boolean) => void
  isMultiSelect?: boolean
}

type option = {
  key: string
  text: string
  selected?: boolean
}

const DropdownInputShared = (props: DropdownInputProps) => {

  const readOnly: boolean = isReadOnly()

  return <BaseDropdownInput
    {...props}
    readOnly={readOnly}
    selected={props.selected || [props.options[0].key]}
  />
}

export default DropdownInputShared
