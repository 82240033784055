import { storageActivity } from "./storageTypes"

const checkFilledDayparts = (newActivities: Array<storageActivity> | undefined): boolean => {
  var containsMissingDayparts: boolean = false
  if (newActivities) {
    newActivities.forEach((activity) => {
      if (activity.DayOptions && activity.DayPartOptions) {
        if (activity.DayOptions.length === 0 && activity.DayPartOptions.length > 0) {
              containsMissingDayparts = true
        } else if (activity.DayOptions.length > 0 && activity.DayPartOptions.length === 0) {
              containsMissingDayparts = true
        }
      }
    })
  }
  return containsMissingDayparts
}

export { checkFilledDayparts }