type globalIdType = "flow" | "page" | "act" | "entities";
type globalIdStringType = "flow" | "page" | "act";
interface valuesType {
  flow: string;
  page: string | undefined;
  entities: Array<string>;
  act: string | undefined;
}

const values: valuesType = {
  flow: "",
  page: undefined,
  entities: [],
  act: undefined
} 

const getGlobalId = (id: globalIdType) => values[id]
const setGlobalId = (id: globalIdStringType, value: string) => {values[id] = value}
const addEntity = (value: string) => {
  // only add new entity if it doesn't exsist yet.
  if (!(values.entities && values.entities[values.entities.length -1] === value)) {
    values.entities?.push(value)
  }
}
const removeLastEntity = () => {values.entities?.pop()}
const isReadOnly = () => Boolean(getGlobalId("act"))

export { getGlobalId, setGlobalId, isReadOnly, addEntity, removeLastEntity }