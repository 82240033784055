import { useState, useEffect } from 'react'
import { useErrorAndLoadingDisplay } from '../../utils'
import { DirectLine } from 'botframework-directlinejs'
import Background from '../../images/qanda-background.png'

import WebChat from '../../components/QandA/WebChat'

import '../../styles/QandA/page.scss'
import { fetchApi } from '../../auth'


type QandADialogProps = {
  appScapeId: string
  dialogId: string
  botIconUrl: string
  userName?: string
}

export default function QandADialog({ appScapeId, dialogId, botIconUrl, userName }: QandADialogProps) {
  const [data, setData] = useState<any>()
  const [directLine, setDirectLine] = useState<DirectLine | undefined>(undefined)
  const dateOptions: dateOptionsProps = { weekday: undefined, year: 'numeric', month: 'long', day: 'numeric' }
  const [error, setError] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const showErrorOrLoading = useErrorAndLoadingDisplay(isLoading, error)

  type dateOptionsProps = {
    weekday: "long" | "short" | "narrow" | undefined
    year: "numeric" | "2-digit" | undefined
    month: "long" | "short" | "narrow" | "numeric" | "2-digit" | undefined
    day: "numeric" | "2-digit" | undefined
  }

  useEffect(() => {
    if (appScapeId && dialogId) {
      const apiUrl = `/api/directline/token/${appScapeId}/${dialogId}`
      const options = {}
      setError(undefined)
      const runFetch = async () => {
        setIsLoading(true)
        try {
          const res = await fetchApi(apiUrl, options)
          const json = await res.json()
          setDirectLine(new DirectLine({ token: json.token }))
          setIsLoading(false)
        }
        finally {
          setIsLoading(false)
        }
      }
      runFetch()
    }

    return () => {
      setDirectLine(undefined);
    };
  }, [appScapeId, dialogId])


  useEffect(() => {
    if (appScapeId) {
      const apiUrl = `/api/appscape/${appScapeId}/categories/${dialogId}`
      const options = {}
      setError(undefined)
      const runFetch = async () => {
        setIsLoading(true)
        try {
          const res = await fetchApi(apiUrl, options)
          const json = await res.json()
          if (!res.ok) {
            setError(`Error ${json.status} - ${json.title}`)
          } else {
            setData(json)
          }
          setIsLoading(false)
        } catch (error) {
          // @ts-ignore
          setError(error)
        }
        finally {
          setIsLoading(false)
        }
      }
      runFetch()
    }

    return () => {
      setData(undefined);
    };
  }, [appScapeId, dialogId])

  return (
    <div id="QandA">
      {showErrorOrLoading}
      {data &&
        <div className='QandA-dialog'>
          <img className="QandA-background" src={Background} alt='desk background' />
          <div className='date-time'>{new Date().toLocaleDateString('en-GB', dateOptions)}</div>
          {directLine && <WebChat directLine={directLine} botName={data.title} botIconUrl={botIconUrl} userName={userName} />}
        </div>
      }
    </div>
  )
}
