import { IAuthProvider } from "../interfaces";
import { BrowserAuthProvider, MobileAppAuthProvider } from "../providers";

export class AuthProviderFactory {
    private static _authProvider: IAuthProvider;

    public static GetProvider = (): IAuthProvider => {
        if (!this._authProvider) {
            if ((window as any).ReactNativeWebView) {
                AuthProviderFactory._authProvider = new MobileAppAuthProvider();
            }
            else {
                AuthProviderFactory._authProvider = new BrowserAuthProvider();
            }
        }

        return AuthProviderFactory._authProvider;
    }
}