
import * as msal from "@azure/msal-browser";
import { LocalSession } from "../../utils/LocalSession";
import { IAuthProvider } from "../interfaces";
import { components } from "../../generated/flowcoordination";
import { UserInfo } from "../typings";


export class BrowserAuthProvider implements IAuthProvider {
    private _isB2C: boolean | undefined;
    private _b2cInvitationAuthority: string | null | undefined;
    private _configEndpoint: string | undefined;
    private _msalConfig: msal.Configuration | undefined;
    private _msalApp: msal.PublicClientApplication | undefined;
    private _account: msal.AccountInfo | undefined;

    private _loginRequest: msal.RedirectRequest | undefined;

    private _defaultConfig: msal.Configuration = {
        auth: {
            clientId: ""
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false,
            secureCookies: false
        },
        system: {
            loggerOptions: {
                loggerCallback: (level: msal.LogLevel, message: string, containsPii: boolean): void => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case msal.LogLevel.Error:
                            console.error(message);
                            return;
                        case msal.LogLevel.Info:
                            console.info(message);
                            return;
                        case msal.LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case msal.LogLevel.Warning:
                            console.warn(message);
                            return;
                    }
                },
                piiLoggingEnabled: false
            },
            windowHashTimeout: 60000,
            iframeHashTimeout: 6000,
            loadFrameTimeout: 0,
            asyncPopups: false
        }
    }

    /**
     * Initializes the msal application. This operation runs two scenario's:
     * 1) Initial application startup; 
     *    the msal application is configured and ready for an login flow.
     * 2) Redirected authentication result; 
     *    as a result of the login flow, an page redirect is initiated which eventually redirects back to the app with all the authentication results.
     *    This is recognized using various local session members.
     * 
     * @returns Promise representing a boolean if authentication should be applied or not.
     */
    public initialize = (redirectToAppScapeUponLogout?: string): Promise<boolean> => {
        return new Promise<boolean>(async (resolve, reject) => {
            this._configEndpoint = `/api/appscape/authConfig`;

            await this.loadConfig(redirectToAppScapeUponLogout);

            // When no msal config member is set, then authentication feature is not enabled
            if (this._msalConfig) {
                this._msalApp = new msal.PublicClientApplication(this._msalConfig);

                this.monitorEvents(this._msalApp);
            }
            else {
                resolve(false);
            }

            this._msalApp = new msal.PublicClientApplication(this._msalConfig as msal.Configuration);
            await this._msalApp.initialize();
            this.monitorEvents(this._msalApp);

            this._msalApp.handleRedirectPromise().then((response: msal.AuthenticationResult | null) => {
                if (response) {
                    this.handleResponse(response);
                }
            });
            resolve(true);
        });
    }

    private monitorEvents = (msalApp: msal.PublicClientApplication) => {
        if (window.location.hostname === "localhost") {
            msalApp.addEventCallback((message) => {
                if (message.eventType === msal.EventType.ACQUIRE_TOKEN_SUCCESS) {
                    const token = (message.payload as any)?.accessToken;
                    if (token) {
                        console.info(`Received new access token:`, token)
                    }
                }
            });
        }
    }

    /**
     * Loads the msal.Configuration class member holding all the required parameters for working with msal.
     */
    private loadConfig = async (redirectToAppScapeUponLogout?: string) => {
        try {
            let loadedConfig;
            if (!LocalSession.hasItem("appScapeConfig")) {
                const response = await fetch(this._configEndpoint ?? "", {})
                loadedConfig = await response.json() as components["schemas"]["VitruCare_Web_AppScape.Models.AppScapeAuthConfig"];
            }
            else {
                loadedConfig = LocalSession.getItem<any>("appScapeConfig") as components["schemas"]["VitruCare_Web_AppScape.Models.AppScapeAuthConfig"];
            }

            if (loadedConfig) {
                const knownAuthority = new URL(loadedConfig.instance ?? "")

                this._isB2C = loadedConfig.isB2C;
                const redirectQuery = redirectToAppScapeUponLogout != null ? `?appscape=${redirectToAppScapeUponLogout}` : '';

                let loadedMsalConfig;
                if (loadedConfig.isB2C) {
                    this._b2cInvitationAuthority = `${loadedConfig.instance}/${loadedConfig.domain}/${loadedConfig.invitationPolicyId}`;
                    loadedMsalConfig = {
                        auth: {
                            clientId: loadedConfig.clientId ?? "",
                            authority: `${loadedConfig.instance}/${loadedConfig.domain}/${loadedConfig.signUpSignInPolicyId}`,
                            knownAuthorities: [knownAuthority.host],
                            redirectUri: window.location.origin,
                            postLogoutRedirectUri: `${window.location.origin}/${redirectQuery}`,
                            navigateToLoginRequestUrl: false
                        }
                    }
                }
                else {
                    loadedMsalConfig = {
                        auth: {
                            clientId: loadedConfig.clientId ?? "",
                            authority: `${loadedConfig.instance}/${loadedConfig.tenantId}`,
                            redirectUri: window.location.origin,
                            postLogoutRedirectUri: `${window.location.origin}/${redirectQuery}`,
                            navigateToLoginRequestUrl: false
                        }
                    }
                }

                this._msalConfig = {
                    ...this._defaultConfig,
                    ...loadedMsalConfig
                }
                console.info("MSAL config was loaded");
            }

            this._loginRequest = {
                scopes: [loadedConfig.scope ?? ""]
            }

            LocalSession.setItem<any>("appScapeConfig", loadedConfig);
        }
        catch (error) {
            throw error;
        }
    }


    /**
     * Gets the account from the current authentication context. 
     * @returns msal.AccountInfo if the user is authenticated.
     */
    private getAccount = (): msal.AccountInfo | undefined => {
        const currentAccounts = this._msalApp?.getAllAccounts();
        if (currentAccounts === null) {
            console.log("No accounts detected");
            return;
        }

        if (currentAccounts && currentAccounts.length > 1) {
            console.log("Multiple accounts detected, need to add choose account code.");
            return currentAccounts[0];
        } else if (currentAccounts && currentAccounts.length === 1) {
            return currentAccounts[0];
        }
    }

    /**
     * Handles the response after the redirect as returned to the app itself.
     * @param response msal.AuthenticationResult instance
     */
    private handleResponse = (response: msal.AuthenticationResult | null) => {
        if (response) {
            this._account = response.account as msal.AccountInfo;
        } else {
            this._account = this.getAccount();
        }

        if (this._account) {
            LocalSession.setItem("skipWelcomeScreen", true);
            if (response?.state) {
                window.location.href = `${window.location.origin}/?appscape=${response?.state}&skipWelcomeScreen=true`;
            }
        } else {
            LocalSession.removeItem("skipWelcomeScreen");
        }
    }

    /**
     * Returns an UserInfo object representing the authenticated user. If no user is authenticated, this method returns undefined.
     * @returns UserInfo instance.
     */
    public getUserInfo = (): UserInfo | undefined => {
        this._account = this.getAccount();

        if (!this._account)
            return undefined;

        let userInfo: UserInfo = {
            id: this._account.localAccountId,
            name: this._account.idTokenClaims?.name || ""
        }

        if (this._isB2C) {
            userInfo = {
                ...userInfo,
                firstName: this._account.idTokenClaims?.given_name,
                lastName: this._account.idTokenClaims?.family_name,
                emailAddress: this._account.idTokenClaims?.email
            } as UserInfo;
        }
        else {
            let firstName: string = "";
            let lastName: string = "";

            if (userInfo.name.length > 0) {
                firstName = userInfo.name.substring(0, userInfo.name.indexOf(" "));
                lastName = userInfo.name.substring(userInfo.name.indexOf(firstName) + firstName.length).trim();
            }

            userInfo = {
                ...userInfo,
                firstName: firstName,
                lastName: lastName,
                emailAddress: this._account.username
            } as UserInfo;
        }

        return userInfo;
    }

    /**
     * Initiates the login flow.
     * @returns Empty Promise.
     */
    public login = async (options: { jwtTokenHint?: string, loginAppScapeId?: string }) => {
        this._account = this.getAccount();

        if (this._account)
            return;

        if (this._msalApp && this._msalConfig && this._loginRequest) {

            if (options.loginAppScapeId) {
                this._loginRequest.state = options.loginAppScapeId;
            }

            if (options.jwtTokenHint) {
                this._loginRequest.extraQueryParameters = {
                    "id_token_hint": options.jwtTokenHint
                };

                this._msalConfig.auth.authority = this._b2cInvitationAuthority!;
                this._msalApp = new msal.PublicClientApplication(this._msalConfig);
                await this._msalApp.initialize();
            }

            await this._msalApp?.loginRedirect(this._loginRequest);
        }
    }

    /**
     * Initiates the logout flow.
     * @returns Empty Promise.
     */
    public logout = async () => {
        if (this._msalApp) {
            let account: msal.AccountInfo | undefined;
            if (this._account) {
                account = this._account
            }
            const logOutRequest: msal.EndSessionRequest = {
                account
            };

            await this._msalApp?.logoutRedirect(logOutRequest);
        }
    }

    /**
     * Acquires an access token for the authenticated user.
     * @returns String containing an access token.
     */
    public async acquireToken(): Promise<string | null> {
        try {
            const response = await this._msalApp?.acquireTokenSilent({
                account: this._account,
                scopes: this._loginRequest?.scopes ?? []
            });

            if (response) {
                return response.accessToken;
            }
        } catch (e) {
            if (e instanceof msal.InteractionRequiredAuthError && this._loginRequest) {
                this._msalApp?.acquireTokenRedirect(this._loginRequest).catch(console.error);
            } else {
                console.error(e);
            }
        }

        return null;
    }

}



