import { useEffect, useState } from "react"
import '../../styles/Pages/index.scss'
import * as pageComponents from "../../pages";

type PageDialogProps = {
    props: any;
    rootComponent: string;
    reset?: boolean;
}

const PageDialog = ({ props, rootComponent, reset }: PageDialogProps) => {
    const [currentComponent, setCurrentComponent] = useState<{ targetPage: string, customProps: any }>()

    useEffect(() => {
        setCurrentComponent({
            targetPage: rootComponent,
            customProps: props
        });
    }, [])

    useEffect(() => {
        if (reset) {
            setCurrentComponent({
                targetPage: rootComponent,
                customProps: props
            });
        }
    }, [reset])

    const onPageNavigate = (targetPage: string, customData: any) => {
        setCurrentComponent({
            targetPage: targetPage,
            customProps: customData
        });
    }

    const renderComponent = () => {
        if (currentComponent) {
            const components = pageComponents as any;
            const Type = components[currentComponent.targetPage];

            return <Type {...currentComponent.customProps} onPageClickEvent={onPageNavigate} />
        }
    }

    return (
        <div className='page-dialog'>
            {renderComponent()}
        </div>
    )
}

export default PageDialog