import { components } from "../../generated/flowcoordination";
import { IAuthProvider } from "../interfaces";
import { LocalSession } from "../../utils/LocalSession";
import { AuthenticationContext, UserInfo } from "../typings";

declare type PostMessageRequest = {
    requestType: string;
}

declare type PostMessageResponse<TData> = {
    requestType: string;
    data: TData;
}

enum RequestType {
    LoginRequest = "dhs.mobile.login",
    LogoutRequest = "dhs.mobile.logout",
    TokenRequest = "dhs.mobile.acquireToken"
}

export class MobileAppAuthProvider implements IAuthProvider {
    private _authenticationContext: AuthenticationContext | undefined;
    private _tokenRequestPromise: Promise<string | null> | undefined;
    private _tokenReceivedPromise: Promise<string | null> | undefined;
    private _authIsRequired: boolean | undefined;

    /**
     * 
     * @param message 
     */
    private postMessage = (message: PostMessageRequest): void => {
        const host: any = (window as any).ReactNativeWebView;

        if (!host)
            throw new Error("App is not running in React Native context.");

        if (!host.postMessage) {
            throw new Error("postMessage is undefined in react native host, make sure the onMessage event is set on the WebView component.");
        }

        host.postMessage(message);
    }

    /**
     * 
     * @param messageData 
     * @returns 
     */
    private parseResponse = <TData>(messageData: any): PostMessageResponse<TData> | undefined => {
        try {
            const postMessageResponse: PostMessageResponse<TData> = messageData as PostMessageResponse<TData>;
            return postMessageResponse;
        }
        catch (error) {
            throw error;
        }
    }

    /**
     * 
     * @param appScapeId 
     * @returns 
     */
    public initialize(redirectToAppScapeUponLogout: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            window.addEventListener("message", (event: MessageEvent) => {
                if (event && event.data) {
                    const response: PostMessageResponse<AuthenticationContext> | undefined = this.parseResponse(event.data);

                    if (response) {
                        switch (response.requestType) {
                            case RequestType.LoginRequest:
                                this._authenticationContext = response.data;
                                break;

                            case RequestType.TokenRequest:
                                this._authenticationContext = response.data;

                                if (this._authenticationContext?.accessToken) {
                                    this._tokenReceivedPromise = Promise.resolve(this._authenticationContext?.accessToken);
                                }
                                break;

                            case RequestType.LogoutRequest:
                                this._authenticationContext = undefined;
                                this._tokenRequestPromise = undefined;
                                this._tokenReceivedPromise = undefined;

                                // after logout redirect to either specified appscape or the default one    
                                const redirectQuery = redirectToAppScapeUponLogout != null ? `?appscape=${redirectToAppScapeUponLogout}` : '';
                                window.location.href = `${window.location.origin}/${redirectQuery}`;
                                break;
                        }

                        resolve(true);
                    }
                    else {
                        console.warn("Unrecognized response received from window message event listener => ", event.data);
                    }
                }
                else {
                    console.warn("Empty message received")
                }
            });

            if (!this._authenticationContext) {
                this.postMessage({
                    requestType: RequestType.LoginRequest
                });
            }
        });
    }

    /**
     * 
     * @returns 
     */
    public getUserInfo(): UserInfo | undefined {
        if (!this._authenticationContext) {
            return undefined;
        }

        return this._authenticationContext.userInfo;
    }

    /**
     * 
     * @returns 
     */
    public login(options: { jwtTokenHint?: string, loginAppScapeId?: string }): Promise<void> {
        return Promise.resolve();
    }

    /**
     * 
     * @returns 
     */
    public logout(): Promise<void> {
        const logoutRequest: PostMessageRequest = {
            requestType: RequestType.LogoutRequest
        }

        this.postMessage(logoutRequest);

        return Promise.resolve();
    }

    /**
     * 
     * @returns 
     */
    public acquireToken(): Promise<string | null> {
        if (!this._authIsRequired)
            return Promise.resolve(null);

        if (this._tokenRequestPromise)
            return this._tokenRequestPromise;

        this._tokenRequestPromise = new Promise<string | null>((resolve, reject) => {
            const tokenRequest: PostMessageRequest = {
                requestType: RequestType.TokenRequest
            }

            this.postMessage(tokenRequest);

            const self = this;
            let guard: number = 0;

            const handler = setInterval(() => {
                if (guard === 100)
                    clearInterval(handler);

                if (self._tokenReceivedPromise) {
                    clearInterval(handler);

                    self._tokenReceivedPromise.then((token: string | null) => {
                        resolve(token);
                    });
                }

                guard++;
            }, 250);
        });

        return this._tokenRequestPromise;
    }
}