import './style.scss'
import CategoryButton from '../CategoryButton'

var classNames = require('classnames')

type CategoryNavigationProps = {
  activeDialog: string
  changeDialogView: (dialogName: string, categoryId: string, newFlowId?: string) => void
  title: string
  categoryGroups: Array<categoryGroups>
  channelNavigationState: boolean
}

type categoryGroups = {
  title: string
  categories: Array<categories>
}

type categories = {
  iconUrl: string
  id: string
  title: string
  dialog: dialogProps
}

type dialogProps = {
  dialogName: string
  infoCards: Array<any>
  introduction: string
  title: string
  flowId?: string;
}

const CategoryNavigation = ({ title, categoryGroups, activeDialog, changeDialogView, channelNavigationState }: CategoryNavigationProps) => {
  const navigationClassNames = classNames('category-navigation', channelNavigationState && 'display')

  return (
    <section id='navigation' className='navigation-container'>
      <div className={navigationClassNames}>
        <div className='category-content'>
          <h2>{title}</h2>
          {
            categoryGroups.map((group: categoryGroups, groupIndex) => {
              return (
                <div className='category-group' key={groupIndex}>
                  {group.title.length > 0 && <h3>{group.title}</h3>}
                  {
                    group.categories.map((category: categories, categoryIndex) => {
                      return <CategoryButton
                        isActive={Boolean(activeDialog === category.id)}
                        key={categoryIndex}
                        isHeadCategory={Boolean(groupIndex === 0 && categoryIndex === 0)}
                        changeDialogView={changeDialogView}
                        {...category}
                      />
                    })
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}

export default CategoryNavigation
