import { CallToActionButton, PrimaryButton } from 'dhs-default-base/dist'
import React, { useCallback, useState, useEffect } from 'react'
import { hooks } from 'botframework-webchat'
import NumericInputControl from './inputs/NumericInputControl'
import './style.scss'
import TitleMessage from '../../CBT/TitleMessage'
import DropdownInputShared from '../../CBT/DropdownInputShared'
import { Label } from 'office-ui-fabric-react'
import TextInputControl from './inputs/TextInputControl'

type trackerComponentProps = {
  content: any
  postSendData: (url: string, payload: any) => Promise<errorType>
}

type errorType = {
  status: number
  message: string
}

type savedData = {
  itemValues: Array<inputData>,
  trackerID: string
}

type inputData = {
  id: string
  values: Array<itemData>
}

type itemData = {
  datasourceId: string,
  value: string
}

const TrackerComponent = ({ content, postSendData }: trackerComponentProps) => {
  const [savedData, setSavedData] = useState<savedData>()
  const [errorText, setErrorText] = useState<string | undefined>(undefined)
  const [errorList, setErrorList] = useState<Array<string>>([])
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const postActivity = hooks.useSendPostBack()
  const contentCallBack = useCallback((payload) => { postActivity(payload) }, [postActivity])

  const translations = content.Translations
  translations.TrackersValidationFieldRange = ""

  const components = content.Components

  useEffect(() => {
    if (content) {

      const newItemArray: Array<itemData> = []
      content.ItemsDatasource.forEach((item: any) => {
        let defaultValue = '';

        content.Components.forEach((component: any) => {
          if (component.componentName !== "DropdownInput") {
            return;
          }

          if (component.DataMappingId === item.Id) {
            if (component.Options && component.Options.length > 0) {
              defaultValue = component.Options[0].DisplayName;
              return;
            }
          }
        });

        newItemArray.push({ datasourceId: item.Id, value: defaultValue })
      })

      const newItems: Array<inputData> = [{ id: content.TrackerDataMappingEntityId, values: newItemArray }]
      setSavedData({ itemValues: newItems, trackerID: content.Id })
    }
  }, [content])

  const buttonClick = () => {
    if (!errorList || errorList.length === 0) {
      setIsDisabled(true)
      if (checkValidation()) {
        setErrorText(undefined)
        postSendData('/api/dialog/sendMappedData', savedData).then((response) => {
          contentCallBack(response)
        }).catch(error => {
          setErrorText("Unexpected error occured while saving readings")
          console.error(error)
          setIsDisabled(false)
        })
      } else {
        setIsDisabled(false)
        setErrorText(translations.TrackersValidationAllFieldsRequired || 'Please fill in all the fields')
      }
    }
  }

  const cancelClick = () => {
    contentCallBack({ status: 204, message: 'Cancel' })
    setIsDisabled(true)
    setErrorText(undefined)
  }

  const checkValidation = (): boolean => {
    var isValid: boolean = true
    const newArray = savedData?.itemValues || []
    const objIndex = newArray.findIndex(obj => obj.id === content.TrackerDataMappingEntityId)
    newArray[objIndex].values.forEach((item) => {
      if (!item.value || item.value === '') {
        isValid = false
      }
    })

    return isValid
  }

  const checkErrors = (datasourceId: string): void => {
    var newErrorList = errorList
    if (!errorList.includes(datasourceId)) {
      newErrorList.push(datasourceId)
      setErrorList(newErrorList)
      setErrorText(translations.TrackersValidationFieldRange || 'Fill only numbers and a maximum of 999')
    }
    if (!newErrorList || newErrorList.length === 0) setErrorText(undefined)
  }

  const onFieldValueChange = useCallback((value: string | undefined, datasourceId: string) => {
    var newErrorList = errorList
    if (errorList.includes(datasourceId)) {
      newErrorList = newErrorList.filter(id => id !== datasourceId)
      setErrorList(newErrorList)
    }
    if (!newErrorList || newErrorList.length === 0) setErrorText(undefined)

    const newSavedData = savedData
    const newArray = newSavedData?.itemValues || []
    const objIndex = newArray.findIndex(obj => obj.id === content.TrackerDataMappingEntityId)
    const objIndex2 = newArray[objIndex].values.findIndex(obj => obj.datasourceId === datasourceId)
    newArray[objIndex].values[objIndex2].value = value || ''
    setSavedData(newSavedData)
  }, [content.TrackerDataMappingEntityId, savedData, errorList])

  function findArrayElementById(array: any[], id: any) {
    return array.find((item: { Id: any }) => {
      return item.Id === id;
    })
  }

  const renderControl = (savedData: savedData, component: {
    ComponentId: string;
    Label: string;
    PlaceholderText: string;
    componentName: string;
    Text: any;
    DataMappingId: string;
    AllowDecimals?: boolean
  }, index: number) => {
    switch (component.componentName) {
      case "TitleMessage":
        return <TitleMessage key={component.ComponentId + "-" + index} componentName={component.componentName} text={component.Text} />

      case "NumericInput":
        savedDataIndex += 1
        const numericDataItem = findArrayElementById(content.ItemsDatasource, component.DataMappingId)
        const item = savedData.itemValues[0].values[savedDataIndex]

        if (component.AllowDecimals) {
          translations.TrackersValidationFieldRange += component.Label + " must be between " + numericDataItem.ValidationMinimum + " and " + numericDataItem.ValidationMaximum + ". "
        }
        else {
          translations.TrackersValidationFieldRange += component.Label + " must be between " + numericDataItem.ValidationMinimum + " and " + numericDataItem.ValidationMaximum + " and should be a non-decimal value. "
        }

        return <NumericInputControl
          key={savedDataIndex}
          value={item.value}
          label={component.Label}
          placeholderText={component.PlaceholderText}
          id={item.datasourceId}
          incrementLabel={translations.TrackersIncreaseValueByOne}
          decrementLabel={translations.TrackersDecreaseValueByOne}
          maxValue={numericDataItem.ValidationMaximum}
          minValue={numericDataItem.ValidationMinimum}
          allowDecimals={component.AllowDecimals ?? false}
          onChange={onFieldValueChange}
          onError={() => checkErrors(item.datasourceId)}
          disabled={isDisabled}
        />

      case "TextInput":
        savedDataIndex += 1
        const textDataItem = findArrayElementById(content.ItemsDatasource, component.DataMappingId)

        return <TextInputControl
          key={savedDataIndex}
          value={savedData.itemValues[0].values[savedDataIndex].value}
          label={component.Label}
          placeholderText={component.PlaceholderText}
          id={savedData.itemValues[0].values[savedDataIndex].datasourceId}
          onChange={onFieldValueChange}
          onError={() => checkErrors(item.datasourceId)}
          disabled={isDisabled}
        />

      case "DropdownInput":
        const dropDownComponent: any = component as any;

        const dropdownOptions = dropDownComponent.Options.map((o: any) => {
          return {
            key: o.Id,
            text: o.DisplayName ?? o.componentName,
            selected: false
          }
        })

        return (
          <React.Fragment key={component.ComponentId}>
            <Label className='input-label'>{component.Label}</Label>
            <DropdownInputShared
              id={component.ComponentId}
              isDisabled={false}
              isMultiSelect={false}
              placeholder={component.Label}
              options={dropdownOptions}
              selected={undefined}
              onChangeFunction={(selectedId, selectedOption) => {
                const dataSourceId = savedData.itemValues[0].values[0].datasourceId;
                onFieldValueChange(selectedOption.text, dataSourceId)
              }} />
          </React.Fragment>
        );
    }
  }

  let savedDataIndex = -1
  return savedData?.itemValues[0] ?
    <div className='tracker-component'>
      {components.map((component: any, index: number) => {
        return renderControl(savedData, component, index);
      })}
      {errorText && <p className="plain error-text">{errorText}</p>}
      <div className='tracker-component--footer'>
        <PrimaryButton text={translations.TrackersCancel || 'Cancel'} clickFunction={cancelClick} disabled={isDisabled} />
        <CallToActionButton name={translations.TrackersSave || 'Save'} clickFunction={buttonClick} disabled={isDisabled} />
      </div>
    </div> : null
}

export default TrackerComponent