import { InfoCard as BaseInfoCard } from 'dhs-default-base/dist'
import './style.scss'

type InfoCardProps = {
  contentName: string
  id: string
  image: string
  text: string
  title: string
  url: string
}

const InfoCard = (props: InfoCardProps) => {
  return (
    <BaseInfoCard {...props} />
  )
}

export default InfoCard
