import { useState, useEffect } from 'react'
import { useErrorAndLoadingDisplay } from '../../utils'
import InfoCard from '../../components/InfoCards/InfoCard'

import '../../styles/InfoCards/page.scss'
import { fetchApi } from '../../auth'

type InfoCardDialogProps = {
  appScapeId: string
  dialogId: string
}

type infoCard = {
  contentName: string
  id: string
  image: string
  text: string
  title: string
  url: string
}

export default function InfoCardDialog({appScapeId, dialogId}: InfoCardDialogProps) {
  const [data, setData] = useState<any>()
  const [error, setError] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const showErrorOrLoading = useErrorAndLoadingDisplay(isLoading, error)

  useEffect(() => {
    if (dialogId) {
      const apiUrl = `/api/appscape/${appScapeId}/categories/${dialogId}`
      const options = {}
      setError(undefined)
      const runFetch = async () => {
        setIsLoading(true)
        try {
          const res = await fetchApi(apiUrl, options)
          const json = await res.json()
          if (!res.ok) {
              setError(`Error ${json.status} - ${json.title}`)
          } else {
            setData(json)
          }
          setIsLoading(false)
        } catch (error) {
          // @ts-ignore
          setError(error)
        }
      }
      runFetch()
      setIsLoading(false)
    }
  }, [appScapeId, dialogId])

  return (
    <div id="info">
      {showErrorOrLoading}
      {data && 
        <div className='info-card-dialog'> 
            <h2 className='h2'>{data.title}</h2>
            <p className='p'>{data.dialog.introduction}</p>
            <div className='info-cards'>
              {
                data.dialog.infoCards && data.dialog.infoCards.map((card: infoCard, index: number) => {
                  return (
                    <InfoCard {...card} key={index} />
                  )
                })
              }
            </div>
        </div>
      }
    </div>
  )
}
