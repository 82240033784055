import '../../styles/CBT/_index.scss'
import 'office-ui-fabric-react/dist/css/fabric.css'
import PageTitle from '../../components/CBT/PageTitle'
import PrimaryButton from '../../components/CBT/PrimaryButton'
import React, { ReactElement } from 'react'
import ProgressBar from '../../components/CBT/ProgressBar'
import { components } from '../../generated/flowcoordination'

type CBTPageProps = {
  pageTitle: string
  headerButtonText?: string
  children: ReactElement<any, any>
  prevButtonText?: string
  nextButtonText?: string
  prevButton?: components["schemas"]["VitruCare_Web_ConversationFlow.Models.PageReference"]
  nextButton?: components["schemas"]["VitruCare_Web_ConversationFlow.Models.PageReference"]
  stopPageId?: string | null
  stopUrl?: string | null
  enableStopButton: boolean
  pageNumber?: number | null
  pageTotal?: number | null
  pageNumberPrefixText?: string
  changePage?: (pageData?: components["schemas"]["VitruCare_Web_ConversationFlow.Models.PageReference"] | undefined, buttonType?: 'stop' | 'continue' | 'next' | 'prev') => void
}

const CBTPage = React.forwardRef<HTMLElement, CBTPageProps>(({ pageTitle, headerButtonText, children, prevButtonText, nextButtonText, prevButton, nextButton, stopPageId, enableStopButton, pageNumber, pageTotal, pageNumberPrefixText, changePage }, ref) => {
  let stopButton = undefined;

  if (enableStopButton && changePage) {
    let pageData: components["schemas"]["VitruCare_Web_ConversationFlow.Models.PageReference"] | undefined = undefined;

    if (stopPageId) {
      pageData = { id: stopPageId || undefined };
    }

    stopButton = <PrimaryButton iconName='Cancel' text={headerButtonText as string} clickFunction={() => changePage(pageData, 'stop')} />
  }

  return (
    <div id="CBT" className='cbt-wrapper'>
      <div className='cbt-content'>
        <header className='cbt-header'>
          <div className='header-wrapper'>
            <PageTitle text={pageTitle} />
            {stopButton}
          </div>
          {(pageNumber && pageTotal) && <ProgressBar current={pageNumber} total={pageTotal} />}
        </header>
        <main ref={ref} id='cbt-main' className='cbt-main'>
          <div className='cbt-center-main'>
            {children}
          </div>
        </main>
        <footer className='cbt-footer'>
          <div className='cbt-navigation'>
            {(prevButtonText && prevButton && changePage) ? (
              <PrimaryButton iconName='ChevronLeft' positionIcon="left" text={prevButtonText} clickFunction={() => changePage(prevButton, 'prev')} />
            ) : <div />}
            <div className='cbt-pageNumber'>{pageNumberPrefixText} {pageNumber}</div>
            {(nextButtonText && nextButton && changePage) ? (
              <PrimaryButton iconName='ChevronRight' positionIcon="right" text={nextButtonText} clickFunction={() => changePage(nextButton, 'next')} />
            ) : <div />}
          </div>
        </footer>
      </div>
    </div>
  )
})

export default CBTPage