import { TextField } from '@fluentui/react/lib/TextField';
import { useCallback } from 'react'
import '../style.scss'
var classNames = require('classnames')

type numericInputControlProps = {
  value: string | undefined
  label: string;
  placeholderText: string;
  id: string
  incrementLabel: string
  decrementLabel: string
  maxValue: number
  minValue: number
  allowDecimals: boolean
  onChange: (value: string, id: string, e: any) => void
  onError: () => void
  disabled: boolean;
}

const NumericInputControl = ({ value, label, placeholderText, id, maxValue, minValue, allowDecimals, onChange, onError, disabled }: numericInputControlProps) => {
  const NumberInputClasses = classNames('number-input', disabled && 'read-only')


  const getDecimalSeparatorOfClient = () => {
    const numberWithDecimalSeparator = 1.1;
    return numberWithDecimalSeparator.toLocaleString().replace(/\d/g, '')
  }

  const parseNumericValue = (value: string) => {
    const regex = allowDecimals ? /^(\d+)(,\d*|\.\d*)?$/ : /^\d+$/;

    if (regex.test(value)) {
      let numericValue = value;

      if (allowDecimals) {
        const decimalSeperator = getDecimalSeparatorOfClient();

        if (decimalSeperator === '.') {
          numericValue = numericValue.replaceAll(",", ".");
        }
        else if (decimalSeperator === ',') {
          numericValue = numericValue.replaceAll(".", ",");
        }
      }

      const parsedValue = allowDecimals ? parseFloat(numericValue) : parseInt(numericValue);
      if (parsedValue > minValue && parsedValue < maxValue) {
        return parsedValue;
      }
    }

    return undefined;
  }

  const onValueChange = useCallback((event: React.SyntheticEvent<HTMLElement>, value: string) => {
    const parsedValue = parseNumericValue(value);

    if (!parsedValue) onError()
    else onChange(parsedValue.toString(), id, event)

  }, [onChange, onError, id, maxValue, minValue])

  return (
    <div className={NumberInputClasses}>
      <TextField
        label={label}
        placeholder={placeholderText}
        inputMode='numeric'
        onChange={(e, value) => onValueChange(e, value || '')}
        pattern='[0-9]*'
        disabled={disabled}
      />
    </div>
  )
}

export default NumericInputControl