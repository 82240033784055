import { useEffect, useState } from "react";
import { components } from "../../generated/flowcoordination";

type TrackerReadingsTableProps = {
    readings: components["schemas"]["VitruCare_Web_ConversationFlow.Models.Trackers.TrackerChart"]
}

const TrackerReadingsTable = ({ readings }: TrackerReadingsTableProps) => {
    const [dataFields, setDataFields] = useState<components["schemas"]["VitruCare_Web_Umbraco.Models.DataMapping.StringMapping"][] | undefined>(undefined);
    const [units, setUnits] = useState<Map<string, components["schemas"]["VitruCare_Web_Umbraco.Models.DataMapping.StringMapping"]> | undefined>(undefined);

    useEffect(() => {
        if (readings && readings.itemsDatasource) {
            const stringMappings = readings.itemsDatasource as components["schemas"]["VitruCare_Web_Umbraco.Models.DataMapping.StringMapping"][];

            let fieldUnitMappings: Map<string, components["schemas"]["VitruCare_Web_Umbraco.Models.DataMapping.StringMapping"]> =
                new Map<string, components["schemas"]["VitruCare_Web_Umbraco.Models.DataMapping.StringMapping"]>();

            for (const mapping of stringMappings) {
                fieldUnitMappings.set(mapping.code || "", mapping)
            }

            setUnits(fieldUnitMappings)
            setDataFields(stringMappings);
        }
    }, [readings]);

    return (
        <>
            {dataFields &&
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            {dataFields.map((dataField) => {
                                return <th key={dataField.id}>{dataField.displayName}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {readings.trackerReadings?.map((reading) => {
                            return (
                                <tr key={reading.trackerReadingId}>
                                    <td>{reading.date ? new Date(reading.date).toLocaleString("en-GB") : "-"}</td>

                                    {reading.values?.map((value, index) => {
                                        const key = `${reading.trackerReadingId}-${index}`
                                        return <td key={key}>{value.value} {units?.get(value.code)?.valueCode}</td>
                                    })}

                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            }
        </>
    )
}

export default TrackerReadingsTable;