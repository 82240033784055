import React from 'react'
import ReactDOM from 'react-dom'
import App from './App.tsx'
import reportWebVitals from './reportWebVitals'

// set window & container height for mobile devices
window.recalculateHeight = () => {
  let windowHeight = window.innerHeight;
  const cookieBanner = document.getElementsByClassName("CookieConsent").item(0);

  // Depending on whether the cookie bar is rendered, we have a different container height (--ch)
  if (cookieBanner) {
    const containerHeight = windowHeight - cookieBanner.clientHeight;
    document.documentElement.style.setProperty('--ch', `${containerHeight * 0.01}px`)
  }
  else {
    document.documentElement.style.setProperty('--ch', `${windowHeight * 0.01}px`)
  }

  document.documentElement.style.setProperty('--vh', `${windowHeight * 0.01}px`)
}

window.setTimeout(() => {
  window.recalculateHeight()
}, 250);

window.addEventListener('resize', () => {
  window.recalculateHeight()
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
