import { ProgressBar as BaseProgressBar } from 'dhs-default-base/dist'

type ProgressBarProps = {
  current: number
  total: number
}

const ProgressBar = (props: ProgressBarProps) => {
  return <BaseProgressBar {...props} />
}

export default ProgressBar
