import '../styles/appscape/_index.scss'
import { ReactElement, useState, useEffect, Dispatch, SetStateAction, useRef } from 'react'
import IdleTimer from 'react-idle-timer'
import CategoryNavigation from '../components/AppScape/CategoryNavigation'
import FooterNavigation from '../components/AppScape/FooterNavigation'
import { useErrorAndLoadingDisplay } from '../utils'
import CBT from '../dialogs/CBT/CBT'
import InfoCardDialog from '../dialogs/InfoCard/InfoCardDialog'
import QandADialog from '../dialogs/QandA/QandADialog'
import { LocalSession } from '../utils/LocalSession'
import { AuthProviderFactory, fetchApi, UserInfo } from '../auth'
import PageDialog from '../dialogs/PageDialog/PageDialog'
import TopNavigation from '../components/AppScape/TopNavigation'

var classNames = require('classnames')

type AppScapeProps = {
  children: ReactElement<any, any>
  channelNavigationState: boolean
  setChannelNavigationState: Dispatch<SetStateAction<boolean>>
  idleTimeout: number;
  onIdle: (e: Event) => void
  onActive: (e: Event) => void
}

export default function AppScape({ children, channelNavigationState, setChannelNavigationState, idleTimeout, onIdle, onActive }: AppScapeProps) {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const [appScapeId] = useState<string>(urlSearchParams.get('appscape') as string)

  const [currentCategory, setCurrentCategory] = useState<any | undefined>()
  const [currentDialogId, setCurrentDialogId] = useState<string>()
  const [inKioskMode, setKioskMode] = useState<boolean>(false)
  const [defaultDialog, setDefaultDialog] = useState<any>()
  const [flowId, setFlowId] = useState<string | undefined>()
  const [activeDialogName, setActiveDialogName] = useState<string>()
  const [resetDialog, setResetDialog] = useState<boolean>(false);
  const [data, setData] = useState<any>()
  const [categories, setCategories] = useState<any>()

  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined)

  const [error, setError] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const idleTimer = useRef<IdleTimer | null>(null)
  const showErrorOrLoading = useErrorAndLoadingDisplay(isLoading, error)
  const appscapeContentClassNames = classNames('appscape-content', channelNavigationState && 'navigation')

  const changeDialogView = (dialogName: string, categoryId: string, newFlowId?: string) => {
    if (activeDialogName === dialogName
      && dialogName === "PageDialog") {
      setResetDialog(true)
    }

    const foundCategory = findCategory(categoryId);
    setCurrentCategory(foundCategory);

    setCurrentDialogId(categoryId)
    setActiveDialogName(dialogName)
    setChannelNavigationState(false)
    setFlowId(newFlowId)
  }

  const findCategory = (categoryId: string): any | undefined => {
    let foundCategory: any | undefined;

    for (const categoryGroup of categories.categoryGroups) {
      for (const category of categoryGroup.categories) {
        if (category.id === categoryId) {
          foundCategory = category;
          break;
        }
      }
    }

    return foundCategory;
  }

  useEffect(() => {
    if (resetDialog) {
      setResetDialog(false)
    }
  }, [resetDialog])


  const initializeAuth = async (currentAppScapeId: string, currentAppScapeRequiresAuth: boolean = false, redirectToAppScapeUponLogout?: string) => {
    const authIsEnabled = await AuthProviderFactory.GetProvider().initialize(currentAppScapeRequiresAuth ? redirectToAppScapeUponLogout : undefined);

    if (authIsEnabled) {
      const authenticatedUser = AuthProviderFactory.GetProvider().getUserInfo();

      if (authenticatedUser) {
        LocalSession.removeItem("skipWelcomeScreen");
        setUserInfo(authenticatedUser);
      }
      else if (currentAppScapeRequiresAuth) {
        LocalSession.setItem("skipWelcomeScreen", true);
        await AuthProviderFactory.GetProvider().login({ loginAppScapeId: currentAppScapeId });
      }
    }
    else {
      setUserInfo(undefined);
      LocalSession.removeItem("skipWelcomeScreen");
      throw new Error("Error while initializing the authentication provider.");
    }
  }


  useEffect(() => {

    /**
     * Fetches the intial appscape data
     */
    const getAppScapeData = async () => {
      const res = await fetchApi(`/api/appscape/${appScapeId}`)
      const json = await res.json()
      if (!res.ok) {
        setError(`Error ${json.status} - ${json.title}`)
      } else {
        setData(json)

        let isAuthRequired = json.isPublic === false;
        await initializeAuth(appScapeId, isAuthRequired, json.appScapeRedirectToLogin);

        const appScapeCategoriesResponse = await fetchApi(`/api/appscape/${appScapeId}/categories`)
        const appScapeCategories = await appScapeCategoriesResponse.json()
        setCategories(appScapeCategories);

        setKioskMode(json.appScapeRedirectToLogin === null && !userInfo)

        if (!currentDialogId) {
          const currentDefaultDialog = appScapeCategories.categoryGroups[0].categories[0];
          setDefaultDialog(currentDefaultDialog)

          setCurrentDialogId(currentDefaultDialog.id)
          setActiveDialogName(currentDefaultDialog.dialog.dialogName)
        }
      }
    }

    setError(undefined)

    try {
      setIsLoading(true);
      getAppScapeData().then(() => {
        setIsLoading(false);
      }).catch((error) => {
        LocalSession.removeItem("skipWelcomeScreen");
        setIsLoading(false);
        setError(error.message);
      });
    }
    catch (error) {
      setIsLoading(false);
      // @ts-ignore          
      setError(error);
    }

    return () => {
      setData(undefined);
      setCategories(undefined);
    };
  }, [])

  const renderDialogView = () => {
    if (!appScapeId)
      return null

    if (currentDialogId) {
      switch (activeDialogName) {
        case 'QnaDialog':
          return <QandADialog
            key={currentDialogId}
            appScapeId={appScapeId}
            dialogId={currentDialogId}
            botIconUrl={categories.categoryGroups[0]?.categories[0]?.iconUrl}
            userName={userInfo?.firstName}
          />
        case 'InfoCardDialog':
          return <InfoCardDialog
            key={currentDialogId}
            appScapeId={appScapeId}
            dialogId={currentDialogId}
          />
        case 'PageDialog':
          if (currentCategory) {
            return <PageDialog
              rootComponent={currentCategory.dialog.type}
              props={
                {
                  title: currentCategory.dialog.title,
                  description: currentCategory.dialog.description
                }
              }
              reset={resetDialog}
            />;
          }
          else {
            return null
          }
        case 'CbtDialog':
          if (flowId)
            return <CBT flowId={flowId} stopPage={() => changeDialogView(defaultDialog.dialog.dialogName, defaultDialog.id)} />
      }
    }

    return null
  }

  const handleAuthenticationChange = async () => {
    if (userInfo) {
      LocalSession.removeItem("skipWelcomeScreen");
      await AuthProviderFactory.GetProvider().logout();
    }
    else {
      window.location.href = `${window.location.origin}/?appscape=${data.appScapeRedirectToLogin}&skipWelcomeScreen=true`;
    }
  }


  return (
    <>
      {showErrorOrLoading}
      {data && categories &&
        <div className='appscape'>
          {inKioskMode &&
            <>
              {/* @ts-ignore */}
              <IdleTimer
                ref={idleTimer as any}
                timeout={idleTimeout}
                onIdle={(event: Event) => {
                  onIdle(event)
                }}
                onActive={(event: Event) => {
                  onActive(event)
                }} />
            </>}
          <header className='appscape-header'>
            <div className="appscape-title"><h1>{data.name}</h1></div>
            {!inKioskMode && <div className="appscape-top-navigation">
              <TopNavigation
                userInfo={userInfo}
                authenticationChange={handleAuthenticationChange}
              />
            </div>}
          </header>
          <main className='appscape-main'>
            <CategoryNavigation
              categoryGroups={categories.categoryGroups}
              activeDialog={currentDialogId || ''}
              title={categories.translations.appScapeNavigation}
              changeDialogView={changeDialogView}
              channelNavigationState={channelNavigationState}
            />
            <div id='appscape' className={appscapeContentClassNames}>
              {activeDialogName && renderDialogView()}
            </div>
          </main>
          <FooterNavigation
            mainChannel={categories.categoryGroups[0].categories[0]}
            changeDialogView={changeDialogView}
            currentDialog={activeDialogName}
            channelNavigationState={channelNavigationState}
            setChannelNavigationState={setChannelNavigationState}
            authIsAvailable={!inKioskMode}
            userInfo={userInfo}
            authenticationChange={handleAuthenticationChange}
          />
        </div>
      }
    </>
  )
}
