import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Icon } from '@fluentui/react/lib/Icon'
import './style.scss'
import { UserInfo } from '../../../auth'
var classNames = require('classnames')

type FooterNavigationProps = {
  mainChannel: channel
  currentDialog: string | undefined
  changeDialogView: (dialogName: string, categoryId: string) => void
  channelNavigationState: boolean
  setChannelNavigationState: Dispatch<SetStateAction<boolean>>

  authIsAvailable: boolean;
  userInfo?: UserInfo
  authenticationChange: () => void;
}

type channel = {
  iconUrl: string
  id: string
  title: string
  dialog: dialogProps
}

type dialogProps = {
  dialogName: string
  infoCards: Array<any>
  introduction: string
  title: string
}

const FooterNavigation = ({ mainChannel, currentDialog, changeDialogView, channelNavigationState, setChannelNavigationState, authIsAvailable, userInfo, authenticationChange }: FooterNavigationProps) => {
  const mainChannelClassNames = classNames('navigation-button main-channel', (currentDialog === 'QnaDialog' && !channelNavigationState) && 'active')
  const channelsButtonClassNames = classNames('navigation-button channels-button', channelNavigationState && 'active')
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    if (userInfo) {
      setLoggedIn(true)
    }
  }, [userInfo])

  const getAuthenticatedButton = () => {
    if (isLoggedIn) {
      return (
        <button className='navigation-button logout' onClick={() => authenticationChange()}>
          <Icon className='icon' iconName='SignOut' />
          <span>Sign out</span>
        </button>
      );
    }
    else {
      return (
        <button className='navigation-button login' onClick={() => authenticationChange()}>
          <Icon className='icon' iconName='Contact' />
          <span>Sign in</span>
        </button>
      );
    }
  }


  return (
    <footer className="appscape-footer">
      <button className={channelsButtonClassNames} onClick={() => setChannelNavigationState(true)}>
        <Icon className='icon' iconName='ConnectContacts' />
        <span>Channels</span>
      </button>
      <button className={mainChannelClassNames} onClick={() => changeDialogView(mainChannel.dialog.dialogName, mainChannel.id)}>
        {mainChannel.iconUrl ?
          <img src={mainChannel.iconUrl} alt='main channel navigation' /> :
          <Icon className='icon vit' iconName='SkypeMessage' />
        }
        <span>{mainChannel.title}</span>
      </button>
      {authIsAvailable &&
        <>
          {getAuthenticatedButton()}
        </>
      }
    </footer>
  )
}

export default FooterNavigation
