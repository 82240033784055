import 'office-ui-fabric-react/dist/css/fabric.css'
import './styles/reset.scss'
import './styles/general.scss'
import { ReactElement, useState, useEffect } from 'react'
import AppScape from './layouts/AppScape'
import LandingPage from './layouts/LandingPage'
import CBT from './dialogs/CBT/CBT'
import LandingBackground from './components/General/LandingBackground'
import { initializeIcons } from '@fluentui/font-icons-mdl2'
import { LocalSession } from './utils/LocalSession'
import CookieBanner from './components/AppScape/CookieBanner'
import { AuthProviderFactory } from './auth'
initializeIcons()

declare global {
  interface Window {
    recalculateHeight(): void;
  }
}

type AppProps = {
  children: ReactElement<any, any>
}

export default function App({ children }: AppProps) {
  const [appScapeId, setAppScapeId] = useState<string>() // '06d0b5d6-5825-4bb8-881b-28309c9b6bd2'
  const [flowId, setFlowId] = useState<string | undefined>()
  const [actAsId, setActAsId] = useState<string | undefined>()
  const [showAppScape, setShowAppScape] = useState<boolean>()
  const [skipWelcomeScreen, setSkipWelcomeScreen] = useState<boolean>(false)
  const [backgroundState, setBackgroundState] = useState<'active' | 'finished' | 'desk' | 'fade'>('active')
  const [channelNavigationState, setChannelNavigationState] = useState<boolean>(false)

  const animateAndGoToAppScape = () => {
    setBackgroundState('desk')

    // wait for zoom animation to end (3s)
    setTimeout(() => {
      setShowAppScape(true)
      setBackgroundState('fade')
    }, 3000)
  }

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const newFlowId: string | null = urlSearchParams.get('flow')
    const newActAsId: string | null = urlSearchParams.get('act')
    const invitation: string | null = urlSearchParams.get('invitation')
    let skipWelcomeScreen: boolean = urlSearchParams.get('skipWelcomeScreen') === true.toString();

    // Check if the local session contains an entry 'skipWelcomeScreen'
    if (!skipWelcomeScreen) {
      skipWelcomeScreen = LocalSession.getItem<boolean>("skipWelcomeScreen") ?? false;
    }

    if (invitation) {
      AuthProviderFactory.GetProvider().initialize().then(() => {
        LocalSession.setItem("skipWelcomeScreen", true);
        AuthProviderFactory.GetProvider().login({ jwtTokenHint: invitation });
      });
      return;
    }

    if (skipWelcomeScreen) {
      setSkipWelcomeScreen(true)
      setShowAppScape(true)
    }

    if (newFlowId) {
      setFlowId(newFlowId)
    }

    if (newActAsId) {
      setActAsId(newActAsId)
    }
  }, [])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const newAppScapeId: string | null = urlSearchParams.get('appscape')
    if (!appScapeId && newAppScapeId)
      setAppScapeId(newAppScapeId)
  }, [appScapeId])

  useEffect(() => {
    if (showAppScape)
      setBackgroundState('finished')
  }, [showAppScape])

  const renderCBTFlowPage = () => {
    if (flowId) {
      return (
        <div className='appscape'>
          <CBT flowId={flowId} actAsId={actAsId} />
        </div>)
    }

    return null
  }

  return (
    appScapeId ?
      <>
        <CookieBanner />
        {!showAppScape && !skipWelcomeScreen && <LandingBackground state={backgroundState} />}
        {
          showAppScape ?
            <>
              <AppScape
                children={children}
                channelNavigationState={channelNavigationState}
                setChannelNavigationState={setChannelNavigationState}
                idleTimeout={1000 * 60 * 10} // 10min
                onIdle={() => {
                  setSkipWelcomeScreen(false)
                  setShowAppScape(false)
                }}
                onActive={() => setShowAppScape(true)} />
            </> :
            <LandingPage children={children} goToAppScape={animateAndGoToAppScape} />
        }
      </>
      : renderCBTFlowPage()
  )
}