import { AuthProviderFactory } from '../factory';

export const fetchApi = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    const token = await AuthProviderFactory.GetProvider().acquireToken();
    let requestInit: RequestInit = init ?? {};

    if (token) {
        if (!requestInit.headers) {
            requestInit.headers = new Headers();
        }

        requestInit.headers = {
            ...requestInit.headers,
            'Authorization': `Bearer ${token}`
        };
    }

    requestInit.headers = {
        ...requestInit.headers,
        'X-Client-TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    return fetch(input, requestInit);
}

