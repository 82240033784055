import { WarningDisplay as BaseWarningDisplay } from 'dhs-default-base/dist'
import './style.scss'

type WarningDisplayProps = {
  text: string
  type: 'error' | 'info'
}

const WarningDisplay = (props: WarningDisplayProps) => {
  return (
    <BaseWarningDisplay {...props} />
  )
}

export default WarningDisplay
