import React, { useState, useEffect } from 'react'
import { Spinner } from '@fluentui/react/lib/Spinner'

const useErrorAndLoadingDisplay = (loadingState: boolean, errorText?: string) => {
  const [error, setError] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    setIsLoading(loadingState)
    if (errorText) {
      setError(typeof errorText === 'string' ? errorText : JSON.stringify(errorText))
    }
  }, [errorText, loadingState, setIsLoading, setError])

  if (error && !isLoading) {
    return <div className='page-error'>{error}</div>
  } else if (isLoading) {
    return <div className='spinner-wrapper'><Spinner /></div>
  }
}

export default useErrorAndLoadingDisplay