import { TextField } from '@fluentui/react/lib/TextField';
import { useCallback } from 'react'
import '../style.scss'
var classNames = require('classnames')

type textInputControlProps = {
    value: string | undefined
    label: string
    placeholderText: string;
    id: string
    onChange: (value: string, id: string, e: any) => void
    onError: () => void
    disabled: boolean;
}

const TextInputControl = ({ value, label, placeholderText, id, onChange, onError, disabled }: textInputControlProps) => {
    const NumberInputClasses = classNames('number-input', disabled && 'read-only')

    const onValueChange = useCallback((event: React.SyntheticEvent<HTMLElement>, value: string) => {
        if (value.trim().length <= 0) onError()
        else onChange(value, id, event)
    }, [onChange, onError, id])

    return (
        <div className={NumberInputClasses}>
            <TextField
                label={label}
                placeholder={placeholderText}
                inputMode='numeric'
                onChange={(e, value) => onValueChange(e, value || '')}
                pattern='[0-9]*'
                disabled={disabled}
            />
        </div>
    )
}

export default TextInputControl