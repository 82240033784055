import styled from 'styled-components'

interface IStyledLandingpageBackground {
  deskPosition: string,
  currentPosition: string,
  backgroundImage: string,
  state: 'active' | 'desk' | 'fade',
  finished: boolean
}

export const StyledLandingpageBackground = styled('div')<IStyledLandingpageBackground>`
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background-repeat: repeat;
  background-image: ${props => props.backgroundImage};
  background-size: auto 100%;
  animation: animatedBackground 250s linear infinite;

  @media (min-width: $tablet) {
    height: 100vh;
  }

  ${props => props.state === 'desk' && `
    animation-name: slideToDesk;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function: ease-in-out;
  `}

  @keyframes animatedBackground {
    from {
      background-position: 0 0;
    }

    to {
      background-position: -10000px 0;
    }
  }

  @keyframes slideToDesk {
    0% {
      background-position: ${props => props.currentPosition} 0;
      transform: scale(1.0)
    }
    85% {
      background-position: ${props => props.deskPosition} 0;
      transform: scale(1.0)
    }
    90% {
      background-position: ${props => props.deskPosition} 0;
      transform: scale(1.0)
    }
    100% {
      background-position: ${props => props.deskPosition} 0;
      transform: scale(1.2)
    }
  }
`;