import ReactWebChat from 'botframework-webchat'
import { createStore } from 'botframework-webchat-core'
import { DirectLine } from 'botframework-directlinejs'
import botIcon from '../../../images/bot-icon.png'
import './style.scss'
import TrackerComponent from '../TrackerComponent'
import { fetchApi } from '../../../auth'
import ChartComponent from '../../AppScape/ChartComponent'

var classNames = require('classnames')

type WebChatProps = {
  directLine: DirectLine
  botName: string
  botIconUrl?: string
  userName?: string
}

type errorType = {
  status: number
  message: string
}

var botIconUrlGlobal: string | undefined

const SenderAvatar = ({ children, fromUser }: any) => {
  const avatarClassNames = classNames('base-avatar', fromUser ? 'user-avatar' : 'bot-avatar')

  return (
    <div className={avatarClassNames}>
      <div className={`app__avatarWithOnlineStatus__status`}>{children}</div>
    </div>
  )
}

const avatarMiddleware = () => (next: any) => ({ fromUser, ...otherArgs }: any) => {
  const renderAvatar = next({ fromUser, ...otherArgs })
  return (
    renderAvatar &&
    (() => (
      <SenderAvatar fromUser={fromUser}>
        {!fromUser && <img className='bot-icon' src={botIconUrlGlobal} alt='bot' />}
        {renderAvatar()}
      </SenderAvatar>
    ))
  )
}

const lowerCaseKeys = (obj: any): any => {
  if (typeof obj !== 'object') {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(lowerCaseKeys);
  }
  if (obj === null) {
    return null;
  }
  const entries = Object.entries(obj);
  const mappedEntries = entries.map(
    ([k, v]) => [
      `${k.substr(0, 1).toLowerCase()}${k.substr(1)}`,
      lowerCaseKeys(v)] as const
  );
  return Object.fromEntries(mappedEntries);
};

const WebChat = ({ directLine, botName, botIconUrl, userName }: WebChatProps) => {
  botIconUrlGlobal = botIconUrl || botIcon

  const postSendData = async (url: string, payload: any) => {
    var errorObject: errorType | undefined = undefined
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json-patch+json',
      },
      body: JSON.stringify(payload)
    }
    try {
      const res = await fetchApi(url, requestOptions)
      if (res.status !== 200) {
        errorObject = { status: 500, message: res.statusText }
        console.error(`${res.statusText} - ${res.status}`)
      } else {
        errorObject = { status: 200, message: 'success' }
      }
    } catch (error) {
      console.error(error)
      errorObject = { status: 500, message: 'catch error' }
    }

    return errorObject
  }

  const attachmentMiddleware = () => (next: any) => (card: any) => {
    switch (card.attachment.content.contentName) {
      case 'Tracker':
        return (
          <TrackerComponent content={card.attachment.content} postSendData={postSendData} />
        )
      case 'TrackerChart':
        const camelCasedContent = lowerCaseKeys(card.attachment.content);

        return (
          <ChartComponent content={camelCasedContent} />
        )

      default:
        return next(card)
    }
  }

  const storeDispatch = ({ dispatch }: any) => (next: any) => async (action: any) => {
    if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
      const userTokenResponse = await fetchApi("/api/user/token");

      if (userTokenResponse?.ok) {
        const contentLength = parseInt(
          userTokenResponse.headers.get("content-length") || "0");

        if (contentLength > 0) {
          console.info("User is authenticated, user token will be dispatched with bot activities");
          const userToken = await userTokenResponse.json();

          if (userToken) {
            dispatch({
              type: 'WEB_CHAT/SEND_EVENT',
              payload: {
                name: "UserData",
                value: {
                  "token": userToken.jwt,
                  "timeZone": Intl.DateTimeFormat().resolvedOptions().timeZone
                }
              }
            });
          }
        }
      }
      else {
        console.info("User is anonymous, no user token is present");
        dispatch({
          type: 'WEB_CHAT/SEND_EVENT',
          payload: {
            name: "AnonymousUser"
          }
        });
      }
    }
    return next(action)
  }

  return (
    <>
      <ReactWebChat
        className='web-chat'
        avatarMiddleware={avatarMiddleware}
        attachmentMiddleware={attachmentMiddleware}
        directLine={directLine}
        username={userName}
        store={createStore({}, storeDispatch)}
        styleOptions={{
          botAvatarInitials: botName,
          userAvatarInitials: 'You',
          suggestedActionLayout: 'stacked',
          timestampColor: "#605E5C",
          hideUploadButton: true,
          autoScrollSnapOnActivity: true
        }}
      />
    </>

  )
}

export default WebChat
