import { PrimaryButton as BasePrimaryButton} from 'dhs-default-base/dist'

type PrimaryButtonProps = {
  text: string
  iconName?: 'Cancel' | 'ChevronLeft' | 'ChevronRight' | 'Delete'
  positionIcon?: 'left' | 'right'
  clickFunction: () => void
  disabled?: boolean
}

const PrimaryButton = (props: PrimaryButtonProps) => {

  return (
    <BasePrimaryButton {...props} />
  )
}

export default PrimaryButton
