import { useEffect, useState } from "react";
import { fetchApi } from "../auth";
import ChartComponent from "../components/AppScape/ChartComponent";
import { components } from "../generated/flowcoordination";
import { useErrorAndLoadingDisplay } from "../utils"
import TrackerReadingsTable from "./components/TrackerReadingsTable";

type TrackerDetailsProps = {
    tracker: components["schemas"]["VitruCare_Web_ConversationFlow.Clients.TrackerInformation"];
}

export const TrackerDetails = ({ tracker }: TrackerDetailsProps) => {
    const [readings, setReadings] = useState<components["schemas"]["VitruCare_Web_ConversationFlow.Models.Trackers.TrackerChart"] | undefined>(undefined)
    const [error, setError] = useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const showErrorOrLoading = useErrorAndLoadingDisplay(isLoading, error)

    useEffect(() => {
        const getReadings = async () => {
            const trackerIdentifier: string = `${tracker?.entityId},${tracker?.externalSystemCodeMapping.system.replaceAll("/", "_")}|${tracker?.externalSystemCodeMapping.code}`

            setIsLoading(true)
            try {
                const res = await fetchApi(`/api/trackers/${encodeURIComponent(trackerIdentifier)}/readings`)

                if (res.ok || res.status === 204) {

                    if (res.status === 200) {
                        const json = await res.json()

                        setReadings(json)
                    }
                    else {
                        setReadings({
                            id: undefined,
                            contentName: "",
                            trackerReadings: undefined,
                        })
                    }
                }
                else {
                    setError(`Error ${res.status} - ${res.statusText}`)
                }

                setIsLoading(false)
            } catch (error) {
                // @ts-ignore
                setError(error)
            }
        }

        if (tracker) {
            getReadings()
            setIsLoading(false)
        }
    }, [tracker])

    const getFormattedLastReading = () => {
        if (readings && readings.trackerReadings) {
            const lastIndex: number = readings.trackerReadings.length - 1;

            if (lastIndex >= 0) {
                const lastTrackerReading = readings.trackerReadings[lastIndex];
                const unitLastReading: components["schemas"]["VitruCare_Web_Umbraco.Models.DataMapping.StringMapping"] | undefined =
                    readings.itemsDatasource?.find((x: components["schemas"]["VitruCare_Web_Umbraco.Models.DataMapping.StringMapping"]) => {
                        return lastTrackerReading.values ? x.code === lastTrackerReading.values[0].code : undefined;
                    });

                if (lastTrackerReading && lastTrackerReading.values) {
                    let valuesOfLastReading = "";

                    lastTrackerReading.values.forEach((x, index) => {
                        valuesOfLastReading += `${x.value} ${unitLastReading?.valueCode}`;

                        if (lastTrackerReading.values &&
                            index < (lastTrackerReading.values.length - 1))
                            valuesOfLastReading += ' / ';
                    });

                    return (
                        <>
                            <span className="tracker-last-reading-values">{valuesOfLastReading}</span>
                            <span className="tracker-last-reading-date">{new Date(lastTrackerReading.date || "").toLocaleDateString("en-GB")}</span>
                        </>
                    );
                }
            }
        }

        return undefined;
    }

    return (
        <>
            {showErrorOrLoading}
            {readings &&
                <div className='tracker-details'>
                    <h2 className='tracker-name'>{tracker.externalSystemCodeMapping.display}</h2>

                    {readings.trackerReadings &&
                        <>
                            <div className='tracker-last-reading'>{getFormattedLastReading()}</div>
                            <div className='tracker-chart'>
                                <ChartComponent content={readings} />
                            </div>
                            <div className="tracker-readings-table">
                                {<TrackerReadingsTable readings={readings} />}
                            </div>
                        </>
                    }

                    {!readings.trackerReadings &&
                        <p>
                            This tracker does not have any readings yet
                        </p>
                    }
                </div>}
        </>
    )
}

