import { useState, useEffect } from 'react'
import './style.scss'
import { defaultOptions, averagesType, rangesType, chartColors, optionsType } from './settings'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'
import HighchartsAccessibility from 'highcharts/modules/accessibility'
import { components } from '../../../generated/flowcoordination'

HighchartsMore(Highcharts)
HighchartsAccessibility(Highcharts)


type trackerComponentProps = {
  content: components["schemas"]["VitruCare_Web_ConversationFlow.Models.Trackers.TrackerChart"]
}

type trackerChartDataSource = {
  code: string;
  display: string;
  valuecode: string;
  rangeValues: number[]
  ranges: rangesType;
  data: averagesType;
}

const ChartComponent = ({ content }: trackerComponentProps) => {
  const [chartOptions, setChartOptions] = useState<optionsType | undefined>(undefined);
  const [isInitialised, setIsInitialised] = useState<boolean>(false)

  const getDefaultOptions = (): optionsType => {
    return {
      series: [],
      tooltip: {
        ...defaultOptions.tooltip,
        formatter: undefined
      },
      xAxis: { ...defaultOptions.xAxis },
      yAxis: { ...defaultOptions.yAxis },
      title: { ...defaultOptions.title }
    };
  }

  useEffect(() => {
    if (!isInitialised && content && content.itemsDatasource) {
      let trackerChartDataSource: trackerChartDataSource[] = []
      let newChartOptions: optionsType = getDefaultOptions();
      let hasTextReadings: boolean = false;

      for (const item of content.itemsDatasource) {
        const itemStringMapping = item as components["schemas"]["VitruCare_Web_Umbraco.Models.DataMapping.StringMapping"];

        trackerChartDataSource.push({
          code: itemStringMapping.code || "",
          display: itemStringMapping.display || "",
          valuecode: itemStringMapping.valueCode || "",
          rangeValues: [itemStringMapping.rangeMinimum || 0, itemStringMapping.rangeMaximum || 0],
          ranges: [],
          data: []
        })
      }

      // When there are tracker value options defined, we need to map them to categories so the chart can display these as labels alongside the y-axis
      if (content.trackerValueOptions) {
        newChartOptions.yAxis.categories = ["", ...[...content.trackerValueOptions].reverse()];
      }

      content.trackerReadings?.forEach((reading: any) => {
        const readingDateParse = new Date(reading.date).getTime();

        reading.values.forEach((value: any) => {
          let parentSource = trackerChartDataSource.find(obj => {
            return obj.code === value.code;
          });

          if (value.type === "Numeric") {
            parentSource?.data.push([readingDateParse, Number(value.value)])
          }
          else if (value.type === "Text") {
            if (content.trackerValueOptions) {
              // We reverse the array because we want to first items in the option list the 'highest' data point value in the chart
              let valueIndex = [...content.trackerValueOptions].reverse().indexOf(value.value);

              if (valueIndex >= 0) {
                parentSource?.data.push([readingDateParse, valueIndex + 1])
              }
            }
          }

          if (parentSource && (parentSource.rangeValues[0] > 0 && parentSource.rangeValues[1] > 0)) {
            parentSource?.ranges.push([readingDateParse, parentSource.rangeValues[0], parentSource.rangeValues[1]])
          }
        })
      })

      newChartOptions.title.text = content.trackerName || ""
      newChartOptions.tooltip.valueSuffix = (content.itemsDatasource[0] as components["schemas"]["VitruCare_Web_Umbraco.Models.DataMapping.StringMapping"]).valueCode || ""

      let colorValue = 0;
      trackerChartDataSource.forEach((dataObject: trackerChartDataSource) => {
        newChartOptions.series.push({
          name: dataObject.display,
          data: dataObject.data,
          id: dataObject.display + "-line",
          color: chartColors[colorValue],
          zIndex: 1,
          marker: {
            fillColor: 'white',
            lineWidth: 2,
            lineColor: chartColors[colorValue]
          }
        }, {
          name: dataObject.display + " range",
          data: dataObject.ranges,
          type: 'arearange',
          lineWidth: 0,
          linkedTo: dataObject.display + "-line",
          color: chartColors[colorValue],
          fillOpacity: 0.3,
          zIndex: 0,
          marker: {
            enabled: false
          }
        })
        colorValue++;
      })

      newChartOptions.tooltip.formatter = function (tooltip) {
        if (this.x && this.y) {
          let textValue = this.y.toString();

          if (newChartOptions.yAxis.categories) {
            textValue = newChartOptions.yAxis.categories[this.y];
          }

          const readingDate = new Date(this.x);
          const readingUnit = trackerChartDataSource[0].valuecode

          let formattedTooltip = `
            <span style='font-size: 10px;'>&nbsp;${readingDate.toLocaleString("en-GB")}</span><br />
            <span>${trackerChartDataSource[0].display}: <b>${textValue} ${readingUnit}</b></span><br />            
          `;

          if (trackerChartDataSource[0].ranges.length > 0) {
            formattedTooltip += `
              <span>Range: <b>${trackerChartDataSource[0].rangeValues[0]} - ${trackerChartDataSource[0].rangeValues[1]} ${readingUnit}</b></span>
            `
          }

          return formattedTooltip;
        }

        // fallback if the condition above is false
        return tooltip.defaultFormatter.call(this, tooltip);
      }

      setChartOptions(newChartOptions);
      setIsInitialised(true)
    }
  }, [content, isInitialised, chartOptions])

  return (
    <div className='chart-component'>
      {isInitialised && <HighchartsReact highcharts={Highcharts} options={chartOptions} />}
    </div>
  )
}

export default ChartComponent