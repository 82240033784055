import { storageProps, constantsProps } from "./storageTypes"
var storageList: Array<storageProps> | undefined = undefined

const updateStorageConstants = (newConstants: constantsProps) => {
  if (storageList && storageList.length > 0) {
    storageList = storageList.filter(component => component.constants?.componentId !== newConstants.componentId)
  } else {
    storageList = []
  }
  storageList.push({options: {}, constants: newConstants})
}

const updateStorageOptions = (componentId: string, newOptions: any) => {
  var newStorageList:Array<storageProps> = []
  storageList?.forEach(component => {
    if (component.constants?.componentId === componentId) {
      component.options = newOptions
    }
    
    newStorageList.push(component)
  })

  storageList = newStorageList
}

const getStorage = () => {
  return storageList
}

const clearStorage = () => {
  storageList = undefined
}

export { updateStorageConstants, updateStorageOptions, getStorage, clearStorage }